import React from "react";
import PowersTop from "./powers-top";
import PowersTable from "./powers-table";

const PowersContent = (props) => {
  const { Arraydata } = props;

  

  return (
    <div className="powers-content">
      <PowersTop />
      <PowersTable Arraydata={Arraydata} />
    </div>
  );
};

export default PowersContent;
