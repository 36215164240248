import React from "react"


const SinginNavbar = () => {
  return (
    <div className="singin__navbar">
        <div className="singin__navbar__logo">
            <img src="/assets/images/logo-light.jfif" alt="logo" />
        </div>
    </div>
  )
}

export default SinginNavbar