/* eslint-disable eqeqeq */
import React, { useState } from "react";
import TrueAndFalseCard from "./true-and-false";
import MultipleChoice from "./multiple-choice";
import DeliveryContent from "./delivery";

const Question = (props) => {
  const [Type, setType] = useState(1);
  const [question, setQuestion] = useState("");
  return (
    <>
      {Type == 1 ? (
        <MultipleChoice
          Type={Type}
          setType={setType}
          question={question}
          setQuestion={setQuestion}
        />
      ) : Type == 2 ? (
        <TrueAndFalseCard
          Type={Type}
          setType={setType}
          question={question}
          setQuestion={setQuestion}
        />
      ) : Type == 3 ? (
        <DeliveryContent Type={Type} setType={setType} />
      ) : null}
    </>
  );
};

export default Question;
