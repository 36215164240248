import React from 'react';
import SurveyViewTop from './survey-view-top';
import SurveyViewTable from './survey-view-table';

const SurveyViewContent = (props) => {
  const { ArrayData,setStartDay,setEndDay,perpage ,pagenumber,setLoading ,PageSize,setPageSize ,classRoomID} = props;
  return (
    <div className='survey-view'>
        <SurveyViewTop  setStartDay={setStartDay} setEndDay={setEndDay} setIsLoading={setLoading} classRoomID={classRoomID}/>
        <SurveyViewTable ArrayData={ArrayData} perpage={perpage} pagenumber={pagenumber} setLoading={setLoading} PageSize={PageSize} setPageSize={setPageSize}/>
    </div>
  )
}

export default SurveyViewContent;


