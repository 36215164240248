import React from 'react'
import MaterialsTop from './materials-top';
import MaterialsTable from './materials-table';

const MaterialsContent = (props) => {
  const { ArrayData,perpage ,pagenumber,setLoading ,PageSize, setPageSize} = props;
  return (
    <div className='materials-content'>
        <MaterialsTop setLoading={setLoading} />
        <MaterialsTable ArrayData={ArrayData} perpage={perpage} pagenumber={pagenumber} setLoading={setLoading} 
          PageSize={PageSize} setPageSize={setPageSize} />
    </div>
  )
}

export default MaterialsContent;

