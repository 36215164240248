import React from "react"
import SinginForm from "./singin-form"


const SinginContent = () => {
    return (
        <div className="singin__content">
            <div className="ovload-img">
            <SinginForm />

            <div className="singin-logo">
                <img src="/assets/images/logo-dark.png" alt="logo" />
            </div>
            </div>
        </div>
    )
}

export default SinginContent