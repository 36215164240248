import React from "react";
import Question from "./question";

const QuestionCard = () => {

  return (
    <div className="add-question-content__card">
      <h6>أضافه سؤال</h6>
      <Question/>
    </div>
  );
};

export default QuestionCard;
