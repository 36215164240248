/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import { Headers } from "../../../../api/actions";
import { Api } from "../../../../api/index";
import axios from "axios";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import axiosInstance from "../../../../api/axios_instance";

const SurveyQuestionsList = (props) => {
  const { ArrayData } = props;
  const { id } = useParams();
  const [data, setData] = useState([]);
  const SurveyQuestion = [];
  const [error, setError] = useState("");
  const [materialsNum, setMaterialsNum] = useState(1);

  // Remove Duplicates from Array Object
  function removeDuplicates(SurveyQuestion, setData) {
    const ids = SurveyQuestion.map(({ id }) => id);
    const filtered = SurveyQuestion.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );
    setData([...filtered]);
  }
  // Loop Array Object and Push to Array
  function loopArray(SurveyQuestion) {
    for (let i = 0; i < ArrayData.length; i++) {
      SurveyQuestion.push({
        id: ArrayData[i].id,
        nameAr: ArrayData[i].nameAr,
        surveyAnswerTypeId: ArrayData[i].surveyAnswerTypeId,
      });
    }
  }
  useEffect(() => {
    loopArray(SurveyQuestion);
    removeDuplicates(SurveyQuestion, setData);
    const options = {
      method: "get",
      url: `${Api}Setting/GetLongTermValue`,
      headers: Headers,
    };
    axiosInstance(options)
      .then(function (response) {
        setMaterialsNum(+response.data.responseObject);
      })
      .catch(function (error) {});
  }, []);
  useEffect(() => {}, []);

  const SubmitAction = (e) => {
    e.preventDefault();
    AddData(id, data, setError);
  };
  console.log(data);

  return (
    <div className="survey-list">
      <div className="survey-list__top">
        <h3> تعديل الاستبيان </h3>
      </div>
      <div className="survey-list__contentquestion">
        {id === "1" && (
          <div className="" style={{ color: "red", textAlign: "center" }}>
            <p>
              يتم اظهر استبيان طويل المدي اذا كانت عدد المواد ف الدورة اكثر من
              او يساوى {materialsNum}.
            </p>
          </div>
        )}
        {id === "2" && (
          <div className="" style={{ color: "red", textAlign: "center" }}>
            <p>
              يتم اظهر استبيان قصير المدي اذا كانت عدد المواد ف الدورة اقل من{" "}
              {materialsNum}.
            </p>
          </div>
        )}
      </div>

      <div className="survey-list__contentquestion">
        {data.map((item, index) => {
          return (
            <SurveyQuestionsListItem
              SurveyQuestion={data}
              setData={setData}
              Item={item}
              Index={index}
              setError={setError}
              key={item?.id + index}
            />
          );
        })}
        {error && <div className="error-text">{error}</div>}
        <AddInput data={data} setData={setData} setError={setError} />

        <ButtonsAll
          SubmitAction={SubmitAction}
          Disabled={data.length === 0 ? true : false}
        />
      </div>
    </div>
  );
};

export default SurveyQuestionsList;

const SurveyQuestionsListItem = (props) => {
  const { SurveyQuestion, Item, Index, setError } = props;
  const [value, setValue] = useState(Item.nameAr);
  return (
    <>
      <div className="survey-list__contentquestion__item">
        <input
          type="text"
          value={value}
          className="form-control survey-list__contentquestion__item__title"
          onChange={(e) => {
            e.preventDefault();
            setValue(e.target.value);
            Item.nameAr = e.target.value;
            SurveyQuestion[Index].nameAr = e.target.value;
            setError("");
          }}
        />
        <SelectBoxTypeAnswer
          SurveyQuestion={SurveyQuestion}
          Index={Index}
          Item={Item}
        />

        {typeof Item.id === "string" && (
          <div className="survey-list__contentquestion__item__btn">
            <button
              type="button"
              className="btn btn-remove"
              data-bs-toggle="modal"
              data-bs-target={`#delete-survey-remove-${Item.id}`}
            >
              <img src="/assets/icons/icon-delete.svg" alt="remove" />
            </button>
          </div>
        )}
      </div>
      <ModalDelete Item={Item} />
    </>
  );
};

const SelectBoxTypeAnswer = (props) => {
  const { SurveyQuestion, Item, Index } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectType, setSelectType] = useState(Item.surveyAnswerTypeId);

  useEffect(() => {
    GetData(setData, setLoading);
  }, []);
  return (
    <select
      name="horns"
      id="horns"
      value={selectType}
      className="survey-list__contentquestion__item__select-box"
      onChange={(e) => {
        e.preventDefault();
        setSelectType(e.target.value);
        Item.surveyAnswerTypeId = selectType;
        SurveyQuestion[Index].surveyAnswerTypeId = e.target.value;
      }}
      disabled={loading === false ? true : false}
    >
      {loading === false ? (
        <option value="0"> نعم او لا</option>
      ) : (
        <>
          {data.map((item, index) => {
            return (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </>
      )}
    </select>
  );
};

const ModalDelete = (props) => {
  const { Item } = props;
  const cancelRef = useRef();
  return (
    <div
      className="modal fade"
      id={`delete-survey-remove-${Item.id}`}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0 mb-3 mt-3">
            <h5
              id="staticBackdropLabel"
              className="w-100 text-center modal-title"
            >
              حذف السؤال
            </h5>
          </div>
          <div className="modal-body border-0 mb-3">
            <p className="w-100 text-center">
              هل أنت متأكد من حذف هذا السؤال ؟ .
            </p>
            {/* <p className="w-100 text-center">
              سيتم مسح هذا السؤال بشكل نهائي من الرسوم البيانية.
            </p> */}
            <p className="w-100 text-center" style={{ color: "red" }}>
              ستفقد اى بيانات مرتبطه بهذا السؤال ولن يتم ظهوره فى الرسوم
              البيانيه
            </p>
          </div>
          <div className="modal-footer border-0 mb-3 d-flex justify-content-center align-items-center">
            <button
              ref={cancelRef}
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              إلغاء
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                console.log(Item, Item.id);

                RemoveItem(Item.id).then(() => cancelRef.current.click());
              }}
            >
              حذف
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const AddInput = (props) => {
  const { data, setData, setError } = props;

  const AddInput = (e) => {
    e.preventDefault();
    for (let i = 0; i < data.length; i++) {
      if (data[i].nameAr === "") {
        setError("يرجى ملء جميع الحقول");
        return false;
      } else {
        setError("");
      }
    }
    data.push({
      id: Math.random() * 1000,
      nameAr: "",
      surveyAnswerTypeId: 1,
    });
    setData([...data]);
  };
  return (
    <div className="multiple-choice__addInput">
      <button
        type="button"
        className="btn button button-next"
        onClick={AddInput}
      >
        أضافه سؤال جديد
      </button>
    </div>
  );
};

const ButtonsAll = (props) => {
  const { SubmitAction, Disabled } = props;
  return (
    <div className="add-question-content__card__buttons">
      <button
        type="button"
        className="btn btn-save"
        disabled={Disabled}
        onClick={(e) => {
          SubmitAction(e);
        }}
      >
        <span>حفظ</span>
      </button>
      <button
        type="button"
        className="btn btn-cansal"
        onClick={(e) => {
          e.preventDefault();
          window.location.href = "/survey";
        }}
      >
        <span>الغاء</span>
      </button>
    </div>
  );
};

// Get Data from API
const GetData = async (setData, setLoading) => {
  const options = {
    method: "GET",
    url: `${Api}Survey/GetSurveyAnswerTypes`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};

const RemoveItem = async (id) => {
  var data = new FormData();
  data.append("id", id);

  const options = {
    method: "post",
    url: `${Api}Survey/DeleteSurveyQuestion`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      swal("تم الحذف بنجاح", {
        icon: "success",
        confirmButtonColor: "#d33",
        button: {
          text: "إغلاق",
          closeModal: true,
        },
      }).then((result) => {
        if (result === true) {
          window.location.reload();
        }
      });
    })
    .catch(function (error) {});
};

const AddData = async (id, Arraydata, setError) => {
  var data = new FormData();
  data.append("surveyTypeId", id);
  for (let i = 0; i < Arraydata.length; i++) {
    if (Arraydata[i].nameAr === "") {
      setError("يرجى ملء جميع الحقول");
      return false;
    } else {
      data.append(`questions[${i}].nameAr`, Arraydata[i].nameAr);
      data.append(
        `questions[${i}].surveyAnswerTypeId`,
        Arraydata[i].surveyAnswerTypeId
      );
      setError("");
    }
  }

  const options = {
    method: "post",
    url: `${Api}Survey/AddEditSurveyQuestion`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      swal("تم  الاضافه  بنجاح", {
        icon: "success",
        confirmButtonColor: "#d33",
        button: {
          text: "إغلاق",
          closeModal: true,
        },
      }).then((result) => {
        if (result === true) {
          window.location.reload();
        }
      });
    })
    .catch(function (error) {});
};
