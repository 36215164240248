/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Api } from "../../../../api";
import { Headers, HeadersFile } from "../../../../api/actions";
import axios from "axios";
import swal from "sweetalert";
import axiosInstance from "../../../../api/axios_instance";

const ModalAddAllQuestion = () => {
  const { id } = useParams();
  const [topping, setTopping] = useState(1);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [ErrorInput, setErrorInput] = useState({
    file: false,
  });
  const OnSubmit = (e) => {
    e.preventDefault();
    if (file === null) {
      setErrorInput({ ...ErrorInput, file: true });
      return;
    } else {
      SendFile(file, setFile, topping, id);
    }
  };

  useEffect(() => {
    GetData(setData, setLoading);
  }, []);

  if (!loading) {
    return (
      <div
        className="modal fade modal-add-moderators"
        id="ModalAddAllQuestion"
        tabIndex="-1"
        aria-labelledby="ModalAddAllQuestionLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="header">
              <h4 className="size-h4">رفع ملف إكسل للأسئلة </h4>

              <button
                type="button"
                className="btn btn-close-modal-add"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M18 6L6 18"
                    stroke="#1C1D22"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6 6L18 18"
                    stroke="#1C1D22"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className="modal fade modal-add-moderators"
      id="ModalAddAllQuestion"
      tabIndex="-1"
      aria-labelledby="ModalAddAllQuestionLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="header">
            <h4 className="size-h4">رفع ملف إكسل للأسئلة </h4>

            <button
              type="button"
              className="btn btn-close-modal-add"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18 6L6 18"
                  stroke="#1C1D22"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 6L18 18"
                  stroke="#1C1D22"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="body">
            <form action="">
              <h6>اختر نوع الاسئلة</h6>

              <div className="input-froms">
                {data.map((item, index) => {
                  return (
                    <div
                      className={
                        topping == item.id ? "active input-from" : "input-from"
                      }
                      key={index}
                    >
                      <input
                        type="radio"
                        id={item.id}
                        name="radio"
                        value={item.id}
                        onChange={(e) => {
                          setTopping(e.target.value);
                          e.target.value = "";
                        }}
                        checked={item.id == topping}
                      />
                      <label htmlFor={item.id}>{item.nameAr}</label>
                    </div>
                  );
                })}
                <div
                  className={topping == 0 ? "active input-from" : "input-from"}
                >
                  <input
                    type="radio"
                    name="radio"
                    id="radio-0"
                    value={"0"}
                    onChange={(e) => {
                      setTopping(e.target.value);
                      e.target.value = "";
                    }}
                    checked={topping == 0}
                  />
                  <label htmlFor="radio-0">أكثر من نوع</label>
                </div>
              </div>

              <div className="input-file">
                {file === null ? (
                  <>
                    <span className="input-file-data">
                      <img src="/assets/icons/icon-upload-blue.svg" alt="" />
                      <span className="text">رفع ملف إكسل</span>
                    </span>

                    <input
                      type="file"
                      accept=".xlsx, .xls, .csv"
                      name="files"
                      value={file === null ? "" : file}
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                        setErrorInput({ ...ErrorInput, file: false });
                      }}
                    />
                  </>
                ) : (
                  <span className="input-file-data-upload">
                    <img src="/assets/icons/icon-upload-file-blue.svg" alt="" />
                    <span className="text">{file.name}</span>
                    <button
                      type="button"
                      className="btn btn-remove"
                      onClick={(e) => {
                        e.preventDefault();
                        setFile(null);
                      }}
                    >
                      <img src="/assets/icons/icon-remove-red.svg" alt="" />
                    </button>
                  </span>
                )}
              </div>
              {ErrorInput.file && (
                <span className="error-text w-100">يجب اختيار ملف</span>
              )}
              <div className="form-group-button">
                <button
                  type="submit"
                  className="btn btn-submit"
                  onClick={(e) => {
                    OnSubmit(e);
                  }}
                >
                  <span>إضافة</span>
                </button>
                <button
                  type="button"
                  className="btn btn-line-susees"
                  data-bs-dismiss="modal"
                  id="Close-ModalAddAllQuestion"
                >
                  <span>إلغاء</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddAllQuestion;

const GetData = async (setData, setLoading) => {
  const options = {
    method: "GET",
    url: `${Api}AnswerType/GetAnswerTypes`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};

const SendFile = async (file, setFile, topping, id) => {
  var data = new FormData();
  data.append("ImportFile", file);
  data.append("SubjectId", id);
  data.append("AnswerTypeId", topping);

  const options = {
    method: "post",
    url: `${Api}Question/ImportQuestionBank`,
    headers: HeadersFile,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setFile(null);
      if (response.data.responseStatus.description === "Error") {
        swal(
          response.data.responseStatus.errors
            .map((err) => err.errorMessage)
            .join("\n"),
          {
            icon: "error",
            showConfirmButton: true,
            confirmButtonColor: "#d33",
            button: {
              text: "إغلاق",
              closeModal: true,
            },
          }
        );
      } else {
        document.getElementById("Close-ModalAddAllQuestion").click();
        swal("تم تحميل الملف بنجاح", {
          icon: "success",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            window.location.reload();
          }
        });
      }
    })
    .catch(function (error) {});
};
