import React from 'react';
import ExamsAddForm from './exams-add-form';

const ExamsAddContent = () => {
  return (
    <div className='exams-content-add'>
      <ExamsAddTop/>
      <ExamsAddForm/>
    </div>
  )
}

export default ExamsAddContent;


const ExamsAddTop=()=>{
    return(
        <div className='exams-content-add__title'>
            <div className='d-flex justify-content-between align-items-center w-100'>
                <h5 className='size-h5'>إضافة اختبار جديد</h5>
            </div >
        </div>
    )
}




