import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Api } from "../../../../api";
import IconSearch from "../../../../components/shared/icon-search";
import { FilterReset } from "../../../../components/shared/reset-filter";
import axios from "axios";
import { Headers } from "../../../../api/actions";
import { downloadBase64File } from "../../../../components/shared/utils";
import LoadingIcon from "../../../../components/shared/icon-loading/icon-loading";
import axiosInstance from "../../../../api/axios_instance";

const MaterialsSubjectTop = (props) => {
  const { ArrayData, setLoading } = props;
  const {
    search: searchKey,
    subjectId,
    classroomId,
    classroomNumber,
  } = useParams();
  let navigate = useNavigate();
  const searchVal = searchKey.split(":")[1] || "";
  const sortSubject = subjectId.slice(
    subjectId.indexOf(":") + 1,
    subjectId.length
  );
  const sortClassRoom = classroomId.slice(
    classroomId.indexOf(":") + 1,
    classroomId.length
  );
  const sortclassroomNumber = classroomNumber.slice(
    classroomNumber.indexOf(":") + 1,
    classroomNumber.length
  );

  const [search, setSearch] = useState(searchVal ?? "");
  const [fileLoading, setFileLoading] = useState(false);

  const options = {
    method: "get",
    url: `${Api}Test/GetSubjectTests_Students_Export?SubjectId=${sortSubject}&ClassRoomId=${sortClassRoom}&ClassRoomNumber=${sortclassroomNumber}`,
    headers: Headers,
  };

  const SearchClick = (e) => {
    e.preventDefault();
    navigate(
      `/materials/material-subject/page:${1}/search:${search}/subjectId:${sortSubject}/classroomId:${sortClassRoom}/classroomNumber:${sortclassroomNumber}`
    );
  };
  const resetFilter = (e) => {
    e.preventDefault();
    navigate(
      `/materials/material-subject/page:${1}/search:/subjectId:${sortSubject}/classroomId:${sortClassRoom}/classroomNumber:${sortclassroomNumber}`
    );
    setLoading(false);
  };
  return (
    <div className="materials-content__top">
      <div className="materials-content__top__title">
        <h5>
          طلاب مادة
          {ArrayData.length === 0 ? (
            ""
          ) : (
            <>
              {ArrayData[0].subjectName} ({ArrayData[0].classRoomName})
            </>
          )}
        </h5>
      </div>

      <div className="materials-content__top__filter">
        <div className="materials-content__top__search">
          <IconSearch SearchClick={SearchClick} />
          <input
            type="text"
            placeholder="بحث"
            defaultValue={search}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                navigate(
                  `/materials/material-subject/page:${1}/search:${
                    e.target.value
                  }/subjectId:${sortSubject}/classroomId:${sortClassRoom}/classroomNumber:${sortclassroomNumber}`
                );
                setLoading(false);
              }
              setSearch(e.target.value);
            }}
          />
        </div>
        {searchVal && <FilterReset resetFilters={resetFilter} />}

        <button
          onClick={async () => {
            setFileLoading(true);
            downloadList(options, setFileLoading);
          }}
          download="Students.Xlsx"
          className="btn btn-download"
        >
          <span className="icon" style={{ transform: "rotate(180deg)" }}>
            {fileLoading && <LoadingIcon size={"30px"} />}{" "}
            {!fileLoading && (
              <img src="/assets/icons/icon-download.svg" alt="" />
            )}{" "}
          </span>
          <span className="text">تحميل القائمة</span>
        </button>
      </div>
    </div>
  );
};

export default MaterialsSubjectTop;

async function downloadList(options, onEnd) {
  await axiosInstance(options)
    .then(function (response) {
      const { fileContents, fileDownloadName, contentType } = response.data;
      downloadBase64File(fileContents, fileDownloadName, contentType);
    })
    .catch(function (error) {})
    .finally(() => onEnd());
}
