import React from 'react'
import TrueAndFalseCard from './true-and-false';
import MultipleChoice from './multiple-choice';

const QuestionCard = (props) => {
    const { data } = props;

    return (
        <div className='add-question-content__card'>
            <h6>عرض السؤال</h6>

            {data.questionTypeId === 1 ? <MultipleChoice Answers={data}  /> : 
            data.questionTypeId === 2 ? 
            <TrueAndFalseCard Answers={data} /> : null}

        </div>
    )
}

export default QuestionCard;