import React from "react";

function AddFormCardFour(props) {
  const { isShowRandomToStudent, setIsShowRandomToStudent } = props;

  return (
    <div className="exams-content-add__form__card">
      <h4 className="size-h4">ثالثا طريقة العرض</h4>
      <div className="exams-content-add__form__card__check-box-inputs">
        <div className="exams-content-add__form__card__check-box-inputs__card-four">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="isShowResult"
              id="isShowResult"
              value={false}
              defaultChecked={isShowRandomToStudent === false ? true : false}
              onChange={(e) => {
                setIsShowRandomToStudent(false);
              }}
            />
            <label className="form-check-label" htmlFor="isShowResult">
              عرض الاسئله للطالب حسب الترتيب الذى تم ادخالها
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="isShowResult"
              id="isShowResult"
              value={true}
              checked={isShowRandomToStudent === true ? true : false}
              onChange={(e) => {
                setIsShowRandomToStudent(true);
              }}
            />
            <label className="form-check-label" htmlFor="isShowResult">
              عرض الاسئله للطالب بشكل عشوائى
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddFormCardFour;
