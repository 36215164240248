import React from 'react';
import ExamsEditForm from './exam-edit-form';

const ExamsEditContent = (props) => {
  const {Arraydata} = props;
  return (
    <div className='exams-content-add'>
      <ExamsEditTop/>
      <ExamsEditForm Arraydata={Arraydata}/>
    </div>
  )
}

export default ExamsEditContent;


const ExamsEditTop=()=>{
    return(
        <div className='exams-content-add__title'>
            <div className='d-flex justify-content-between align-items-center w-100'>
                <h5 className='size-h5'>
                  تعديل الاختبار
                </h5>
            </div >
        </div>
    )
}




