/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Api } from "../../../api/index";
import { Headers } from "../../../api/actions";
import swal from "sweetalert";
import { ClipLoader } from "react-spinners";
import { useNavigate, useParams } from "react-router";
import Loading from "../../../components/loading/loading";
import axiosInstance from "../../../api/axios_instance";

const EditePowersContent = (props) => {
  const { Arraydata } = props;
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    GetData(id, setData, setLoading);
  }, []);

  if (!loading) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <>
      <PowersContent Arraydata={Arraydata} DataUser={data} />
    </>
  );
};

export default EditePowersContent;

const PowersContent = (props) => {
  const { Arraydata, DataUser } = props;
  const IdPowrs = DataUser.id;
  const [name, setName] = useState(DataUser.name);
  const [code, setCode] = useState(DataUser.code);
  const [allChecked, setAllChecked] = useState([...DataUser.permissions]);
  const [ErrorInput, setErrorInput] = useState({
    name: false,
    allChecked: false,
  });
  return (
    <div className="powers-content-add">
      <div className="powers-content-add__top">
        <div className="powers-content-add__top__title">
          <h5>إضافة صلاحية جديدة</h5>
        </div>
        <div className="powers-content-add__top__search">
          <AddPowersTop
            Value={name}
            setValue={setName}
            ErrorInput={ErrorInput}
            setErrorInput={setErrorInput}
          />
          {ErrorInput.name === true && (
            <span className="error-text w-100">الرجاء ادخال اسم الصلاحية</span>
          )}
          <AddPowersCode
            Value={code}
            setValue={setCode}
            ErrorInput={ErrorInput}
            setErrorInput={setErrorInput}
          />
          {ErrorInput.code === true && (
            <span className="error-text w-100">الرجاء ادخال كود الصلاحية</span>
          )}
        </div>
      </div>
      <EditePowersTable
        Arraydata={Arraydata}
        allChecked={allChecked}
        setAllChecked={setAllChecked}
        ErrorInput={ErrorInput}
        setErrorInput={setErrorInput}
      />
      {ErrorInput.allChecked === true && (
        <span className="error-text w-100">الرجاء تحديد الصلاحيات</span>
      )}
      <EditePowersButton
        name={name}
        code={code}
        allChecked={allChecked}
        setAllChecked={setAllChecked}
        setName={setName}
        ErrorInput={ErrorInput}
        setErrorInput={setErrorInput}
        IdPowrs={IdPowrs}
      />
    </div>
  );
};

const AddPowersTop = (props) => {
  const { Value, setValue, ErrorInput, setErrorInput } = props;

  const OnChinge = (e) => {
    e.preventDefault();
    setValue(e.target.value);
    setErrorInput({ ...ErrorInput, name: false });
  };
  return (
    <input
      type="text"
      placeholder="اكتب اسم الصلاحية"
      value={Value}
      onChange={(e) => {
        OnChinge(e);
      }}
    />
  );
};

const AddPowersCode = (props) => {
  const { Value, setValue, ErrorInput, setErrorInput } = props;

  const OnChinge = (e) => {
    e.preventDefault();
    setValue(e.target.value);
    setErrorInput({ ...ErrorInput, code: false });
  };
  return (
    <input
      type="text"
      placeholder="اكتب كود الصلاحية"
      value={Value}
      onChange={(e) => {
        OnChinge(e);
      }}
    />
  );
};
const EditePowersTable = (props) => {
  const { Arraydata, allChecked, setAllChecked, ErrorInput, setErrorInput } =
    props;
  return (
    <div className="powers-content-add__table">
      <div className="powers-content-add__table__title">
        <h5>تحديد الصلاحيات</h5>
      </div>
      <div className="powers-content-add__table__row">
        {Arraydata.map((item, index) => {
          return (
            <div className="powers-content-add__table__row__card" key={index}>
              <PowersCard
                Item={item}
                allChecked={allChecked}
                setAllChecked={setAllChecked}
                ErrorInput={ErrorInput}
                setErrorInput={setErrorInput}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const PowersCard = (props) => {
  const { Item, allChecked, setAllChecked, ErrorInput, setErrorInput } = props;
  const ArrayClaim = [...Item.claims];
  const ArrayClaimCode = [];

  for (let i = 0; i < ArrayClaim.length; i++) {
    ArrayClaimCode.push(ArrayClaim[i].claimCode);
  }

  const handleCheck = (e) => {
    const { value } = e.target;
    if (e.target.checked === true) {
      setAllChecked([...allChecked, value]);
    } else {
      setAllChecked(allChecked.filter((item) => item !== value));
    }
    setErrorInput({ ...ErrorInput, allChecked: false });
  };

  const handleCheckAll = (e) => {
    if (e.target.checked === true) {
      setAllChecked([...ArrayClaimCode, ...allChecked]);
    } else {
      const valueAll = allChecked.filter(
        (item) => !Item.claims.map((item) => item.claimCode).includes(item)
      );
      setAllChecked(valueAll);
    }
    setErrorInput({ ...ErrorInput, allChecked: false });
  };

  return (
    <>
      <div className="head">
        <h6>
          <label>
            <input
              type="checkbox"
              name="classes"
              id="classes"
              className="big black"
              onChange={handleCheckAll}
              checked={Item.claims
                .map((item) => item.claimCode)
                .every((item) => allChecked.includes(item))}
            />
            <span>{Item.claimTypeName}</span>
          </label>
        </h6>
      </div>
      <div className="body">
        {Item.claims.map((item, index) => {
          return (
            <div className="item" key={index}>
              <label htmlFor={item.claimId}>
                <input
                  type="checkbox"
                  name={item.claimId}
                  id={item.claimId}
                  value={item.claimCode}
                  checked={allChecked.includes(item.claimCode)}
                  className="big blue"
                  onChange={handleCheck}
                />
                <span>{item.claimName}</span>
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
};

const EditePowersButton = (props) => {
  const {
    name,
    code,
    allChecked,
    setAllChecked,
    setName,
    ErrorInput,
    setErrorInput,
    IdPowrs,
  } = props;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const OnClick = (e) => {
    e.preventDefault();
    if (name === "") {
      setErrorInput({ ...ErrorInput, name: true });
    } else if (code === "") {
      setErrorInput({ ...ErrorInput, code: true });
    } else if (allChecked.length === 0) {
      setErrorInput({ ...ErrorInput, allChecked: true });
    } else {
      SendData(
        IdPowrs,
        name,
        code,
        allChecked,
        setLoading,
        setAllChecked,
        setName
      );
      setLoading(false);
    }
  };
  return (
    <div className="powers-content-add__button">
      <button
        className="btn btn-primary-blue"
        type="button"
        onClick={OnClick}
        disabled={loading === true ? false : true}
      >
        <span className="text">تعديل</span>
        {loading === false ? <ClipLoader color="#231f20" size={25} /> : null}
      </button>

      <button
        type="button"
        className="btn btn-line-susees"
        onClick={() => {
          setAllChecked([]);
          navigate(-1);
        }}
      >
        <span className="text">إلغاء</span>
      </button>
    </div>
  );
};

const SendData = async (
  IdPowrs,
  name,
  code,
  allChecked,
  setLoading,
  setAllChecked,
  setName
) => {
  var data = new FormData();
  data.append("name", name);
  data.append("code", code);
  data.append("id", IdPowrs);
  for (let i = 0; i < allChecked.length; i++) {
    data.append(`permissions[${i}]`, allChecked[i]);
  }

  const options = {
    method: "post",
    url: `${Api}Role/AddEditRole`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setLoading(true);
      if (response.data.responseStatus.description === "Success") {
        swal("تمت التعديل  بنجاح", {
          icon: "success",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            window.location.href = "/powers/search:";
          }
        });
      }
    })
    .catch(function (error) {
      setLoading(true);
    });
};

const GetData = async (id, setData, setLoading) => {
  const options = {
    method: "post",
    url: `${Api}Role/GetClaimByRoleID`,
    headers: Headers,
    data: {
      id: id,
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
