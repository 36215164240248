import React from "react";
import { useParams } from "react-router";

import NavbarLeft from "../../../components/navbar/navbar_left.jsx";
import NavbarTop from "../../../components/navbar/navbar_top.jsx";
import ChoiceAddQuestionContent from "../component/add-question-content/index.jsx";

const AddQuestion = () => {
  const { subjectid } = useParams();
  const Subjectid = subjectid.slice(
    subjectid.indexOf(":") + 1,
    subjectid.length
  );

  const ObJectArrayData = [
    {
      id: 2,
      href: `/questionbanck/add-all/page:1/${Subjectid}`,
      title: "بنك الاسئلة",
    },
  ];

  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <ChoiceAddQuestionContent />
        </div>
      </div>
    </section>
  );
};

export default AddQuestion;
