/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Api } from "../../../api/index";
import { Headers } from "../../../api/actions";
import axios from "axios";
import IconSearch from "../../../components/shared/icon-search";
import { FilterReset } from "../../../components/shared/reset-filter";
import axiosInstance from "../../../api/axios_instance";

const QuestionTop = (props) => {
  const { setLoading, ArrayData } = props;
  const { sortclassroom, search: searchKey } = useParams();
  const searchVal = searchKey.split(":")[1];
  let navigate = useNavigate();
  const sortClassRoom = sortclassroom.slice(
    sortclassroom.indexOf(":") + 1,
    sortclassroom.length
  );
  const [search, setSearch] = useState(searchVal ?? "");
  const SearchClick = (e) => {
    e.preventDefault();
    if (search !== searchVal) {
      navigate(
        `/questionbanck/page:${1}/search:${search}/sortclassroom:${sortClassRoom}`
      );
      setLoading(false);
    }
  };
  const resetFilters = () => {
    navigate(`/questionbanck/page:${1}/search:/sortclassroom:`);
    setLoading(false);
  };
  return (
    <div className="d-flex justify-content-between align-items-center w-100">
      <h5 className="size-h5">بنك الاسئلة</h5>

      <div className="question-content__top__filter">
        <div className="input-group-search">
          <IconSearch SearchClick={SearchClick} />
          <input
            type="text"
            placeholder="بحث"
            defaultValue={search}
            onKeyUp={(e) => {
              console.log();
              if (e.keyCode === 13 && e.target.value !== searchVal) {
                navigate(
                  `/questionbanck/page:${1}/search:${
                    e.target.value
                  }/sortclassroom:${sortClassRoom}`
                );
                setLoading(false);
              }
              setSearch(e.target.value);
            }}
          />
        </div>
        <QuestionDropdown ArrayData={ArrayData} setLoading={setLoading} />
        {(searchVal || sortClassRoom) && (
          <FilterReset resetFilters={resetFilters} />
        )}
      </div>
    </div>
  );
};

export default QuestionTop;

const QuestionDropdown = (props) => {
  const { ArrayData } = props;
  let navigate = useNavigate();
  const { search, sortclassroom } = useParams();
  const searchKeyword = search.slice(search.indexOf(":") + 1, search.length);
  const sortClassRoom = sortclassroom.slice(
    sortclassroom.indexOf(":") + 1,
    sortclassroom.length
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    const { value } = event.currentTarget;
    setValue(value);
  };
  useEffect(() => {
    GetData(setData, setLoading);
  }, []);

  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <div>
          <span className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                d="M14.6668 2.5H1.3335L6.66683 8.80667V13.1667L9.3335 14.5V8.80667L14.6668 2.5Z"
                stroke="#53545C"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span className="text">تصفية</span>
        </div>
      </button>
      <ul className="dropdown-menu">
        <p>
          <span>تصفية حسب الدورة </span>
        </p>
        {data.map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="radio"
                name="sortclassroom"
                value={item.id}
                id="flexCheckDefault"
                onChange={handleChange}
                checked={item.id == sortClassRoom ? true : null}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>{item.nameAr}</span>
              </label>
            </li>
          );
        })}
        <button
          type="button"
          className="btn-sendapi"
          onClick={(e) => {
            e.preventDefault();
            navigate(
              `/questionbanck/page:${1}/search:${searchKeyword}/sortclassroom:${value}`
            );
            window.location.reload();
          }}
        >
          <span>تصفية</span>
        </button>
      </ul>
    </div>
  );
};

const GetData = async (setData, setLoading) => {
  const options = {
    method: "post",
    url: `${Api}ClassRoom/SearchForClassRooms`,
    headers: Headers,
    data: {
      pageNumber: 1,
      searchKeyword: "",
      pageSize: 1000000000,
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      // console.log(response.data.responseObject);
      setData(response.data.responseObject.items);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
