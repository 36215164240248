import React from 'react'
import NavbarTop from '../../components/navbar/navbar_top';
import NavbarLeft from '../../components/navbar/navbar_left';
import SettingsContent from './component/content';


const ProfilePersonly = () => {
    const ObJectArrayData = [
        {
            id: 9,
            href: "/profile-personly",
            title: "الملف الشخصي",
        }
    ]
  return (
    <section className='main'>
        <NavbarLeft/>
        <div className="main-body">
            <NavbarTop ArrayData={ObJectArrayData}/>
            <div className="main-body__content">
                <SettingsContent/>
            </div>
        </div>
    </section>
  )
}

export default ProfilePersonly;