import React  from 'react'

import NavbarLeft from '../../../components/navbar/navbar_left'
import NavbarTop from '../../../components/navbar/navbar_top'
import ExamsRepetitonContent from '../component/exam-repetiton/exams-repetiton-content'

const ExamsRepetiton = () => {

    const ObJectArrayData = [
        {
            id: 2,
            href: `/exams/page:${1}/search:${""}`,
            title: "الاختبارات",
        }
    ]
  return (
    <section className='main'>
        <NavbarLeft />
        <div className="main-body">
            <NavbarTop ArrayData={ObJectArrayData} />
            <div className="main-body__content">
                <ExamsRepetitonContent/>
            </div>
        </div>

    </section>
  )
}

export default ExamsRepetiton;


