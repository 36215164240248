import React from "react";

const HomeNavList = (props) => {
    const {filter,setFilter} = props;
  const ChingeNavList = (e, value) => {
    e.preventDefault();
    setFilter(value);
  };

  const ArrayList =[
    {
        id : 1,
        name : "إحصائيات  عامة",
        value : "classroom"
    },{
        id : 4,
        name : "الاستبيانات",
        value : "survey"
    }
  ]
  return (
    <div className="home-content__nav-list">
        {ArrayList.map((item) => (
            <button type="button" key={item.id}
            className={filter === item.value ? "btn btn-active" : "btn"}
                onClick={(e) => ChingeNavList(e, item.value)}>
                <span>{item.name}</span>
            </button>
        ))}
    </div>
  );
};

export default HomeNavList;


/*


  const ArrayList =[
    {
        id : 1,
        name : "الدورات التعليمية",
        value : "classroom"
    },
    {
        id : 2,
        name : "الأختبارات",
        value : "exams"
    },
    {
        id : 3,
        name : "الطلاب",
        value : "students"
    },{
        id : 4,
        name : "الاستبيانات",
        value : "survey"
    }
  ]
*/