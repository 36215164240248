import React from "react";
import axios from "axios";
import { Api } from "../../../../api/index.js";
import { Headers } from "../../../../api/actions";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import axiosInstance from "../../../../api/axios_instance.jsx";

const ListStudentsTable = (props) => {
  const { ArrayData } = props;

  return (
    <div className="table-container">
      <ListStudentsTableHead />
      <>
        <ul className="table-container__body">
          {ArrayData.map((item, index) => {
            return (
              <li key={index}>
                <ListStudentsTableBody Item={item} />
              </li>
            );
          })}
        </ul>
      </>
    </div>
  );
};

export default ListStudentsTable;

const ListStudentsTableHead = () => {
  return (
    <ul className="table-container__head">
      <li>
        <span className="justify-content-start">اسم الطالب</span>
        <span>رقم الهوية</span>
        <span>الحالة</span>
        {/*
        <span className="justify-content-end">الإجراءات</span>
         */}
      </li>
    </ul>
  );
};

const ListStudentsTableBody = (props) => {
  const { Item } = props;
  const { id } = useParams();

  return (
    <>
      <span className="justify-content-start">{Item.studentName}</span>
      <span>{Item.nationalId} </span>
      <span> {Item.statusInTest}</span>
      {/*
      <span className="link-list justify-content-end">
        <button type="button" className="btn btn-show-click" 
        onClick={(e)=>{
          e.preventDefault();
          SenData(Item);
        }}>
          <span>
            إعادة الاختبار
          </span>
        </button>
      </span>
        */}
    </>
  );
};

const SenData = async (Item) => {
  var data = new FormData();
  data.append("studentId", Item.studentId);
  data.append("testId", Item.testId);

  const options = {
    method: "post",
    url: `${Api}Test/ReturnStudentToTest`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      swal("تم إعادة الطالب الى الاختبار بنجاح", {
        icon: "success",
        confirmButtonColor: "#d33",
        button: {
          text: "إغلاق",
          closeModal: true,
        },
      }).then((result) => {
        if (result === true) {
          window.location.reload();
        }
      });
    })
    .catch(function (error) {});
};
