/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Api } from "../../../../api";
import { Headers } from "../../../../api/actions";
import axios from "axios";
import axiosInstance from "../../../../api/axios_instance";

const SurveyViewTopDropdownClassRoomName = (props) => {
  const { id } = useParams();
  const { setIsLoading, classRoomID } = props;
  let navigate = useNavigate();
  const [value, setValue] = useState("");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetData(setData, setLoading);
  }, []);

  if (!loading) {
    return (
      <button className="btn btn-show-dropdown" type="button">
        <span className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M14.6668 2.5H1.3335L6.66683 8.80667V13.1667L9.3335 14.5V8.80667L14.6668 2.5Z"
              stroke="#53545C"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span className="text">تصفية حسب الدورة </span>
      </button>
    );
  }
  const handleChange = (event) => {
    const { value } = event.currentTarget;
    setValue(value);
  };

  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M14.6668 2.5H1.3335L6.66683 8.80667V13.1667L9.3335 14.5V8.80667L14.6668 2.5Z"
              stroke="#53545C"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span className="text">تصفية حسب الدورة </span>
      </button>
      <ul className="dropdown-menu">
        {data.map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="radio"
                value={item.id}
                id="flexCheckDefault"
                name="classRoomIDCheckDefault"
                onChange={handleChange}
                checked={classRoomID == item.id ? true : null}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>{item.nameAr}</span>
              </label>
            </li>
          );
        })}
        <button
          type="button"
          className="btn-sendapi"
          onClick={(e) => {
            e.preventDefault();
            navigate(
              `/survey/survey-view/${id}/page:1/search:/sortclassroom:${value}`
            );
            setIsLoading(false);
          }}
        >
          <span>تصفية</span>
        </button>
      </ul>
    </div>
  );
};
export default SurveyViewTopDropdownClassRoomName;

const GetData = async (setData, setLoading) => {
  var data = new FormData();
  data.append("pageNumber", 1);
  data.append("searchKeyword", "");
  data.append("pageSize", 10);
  const options = {
    method: "post",
    url: `${Api}ClassRoom/SearchForClassRooms`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.items);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
