import React, { useState } from "react";

const ProfilePersonlyForm = () => {
  return (
    <form action="">
      <div className="bottom">
        <div className="image">
          <img src="/assets/images/logo-light.jfif" alt="avatar" />
        </div>

        <div className="form">
          <div className="from-group">
            <label htmlFor="">اسم المستخدم</label>
            <div className="input-group">
              <img src="/assets/icons/icon-profile.svg" alt="" />
              <input type="text" value={"عثمان محمد على"} disabled />
            </div>
          </div>
          <div className="from-group">
            <label htmlFor="">الرقم العسكري</label>
            <div className="input-group">
              <img src="/assets/icons/fi_tablet.png" alt="" />
              <input type="number" value={"123456789"} disabled />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ProfilePersonlyForm;