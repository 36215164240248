/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Api } from "../../../../api";
import axios from "axios";
import { Headers } from "../../../../api/actions";
import ChartRow from "./chart-sections";
import axiosInstance from "../../../../api/axios_instance";

const ListSurveyStudentsChart = (props) => {
  const { surveytypeid, subjectid } = useParams();
  const subjectId = subjectid.slice(
    subjectid.indexOf(":") + 1,
    subjectid.length
  );
  const surveyTypeId = surveytypeid.slice(
    surveytypeid.indexOf(":") + 1,
    surveytypeid.length
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetData(surveyTypeId, setData, setLoading);
  }, [surveyTypeId]);

  if (!loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="survey-view__chart">
      <SurveyStudentsChartItem data={data} subjectId={subjectId} />
    </div>
  );
};

export default ListSurveyStudentsChart;

const SurveyStudentsChartItem = (props) => {
  const { data, subjectId } = props;
  const [quenId, setQuenId] = useState(data[0].id || 0);
  const [name, setName] = useState(data[0].nameAr || "");

  const handleChange = (value) => {
    setQuenId(value);
  };
  return (
    <>
      <DropDwond
        quenId={quenId}
        data={data}
        handleChange={handleChange}
        setName={setName}
      />
      <ExamsChart quenId={quenId} subjectId={subjectId} name={name} />
    </>
  );
};
const DropDwond = (props) => {
  const { quenId, data, handleChange, setName } = props;
  const [value, setValue] = useState(0);
  const [tmpName, setTmpName] = useState("");
  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <div>
          <span className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                d="M14.6668 2.5H1.3335L6.66683 8.80667V13.1667L9.3335 14.5V8.80667L14.6668 2.5Z"
                stroke="#53545C"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span className="text">أحصائيه حسب السؤال</span>
        </div>
      </button>
      <ul className="dropdown-menu">
        <p>
          <span>تصفية حسب الاسئله </span>
        </p>
        {data.map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="radio"
                name="sortclassroom"
                value={item.id}
                defaultChecked={item.id == quenId}
                id="flexCheckDefault"
                onChange={() => {
                  setValue(item.id);
                  setTmpName(item.nameAr);
                  // setName(item.nameAr);
                }}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>{item.nameAr}</span>
              </label>
            </li>
          );
        })}
        <button
          type="button"
          className="btn-sendapi"
          onClick={(e) => {
            if (tmpName) setName(tmpName);
            handleChange(value);
          }}
        >
          <span>تصفية</span>
        </button>
      </ul>
    </div>
  );
};
const ExamsChart = (props) => {
  const { quenId, subjectId, name } = props;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataChart(quenId, subjectId, setData, setLoading);
  }, [quenId]);

  if (!loading) {
    return <></>;
  }
  return (
    <>
      <ChartRow Arraydata={data} name={name} />
    </>
  );
};

const GetData = async (id, setData, setLoading) => {
  var data = new FormData();
  data.append("id", id);

  const options = {
    method: "post",
    url: `${Api}Survey/GetSurveyQuestionsBySurveyType`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};

const GetDataChart = async (quenId, subjectId, setData, setLoading) => {
  var data = new FormData();
  data.append("subjectId", subjectId);
  data.append("surveyQuestionId", quenId);
  const options = {
    method: "POST",
    url: `${Api}Charts/SurveyQuestionPercentages`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
