import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import IconSearch from '../../../../components/shared/icon-search';

const ListSurveyStudentsTop = (props) => {
    const { ArrayData,setLoading } = props;
    const {  sortclassroom, subjectid,surveytypeid } = useParams();
    let navigate = useNavigate();
    const classRoom = sortclassroom.slice(sortclassroom.indexOf(":") + 1, sortclassroom.length);
    const subject = subjectid.slice(subjectid.indexOf(":") + 1, subjectid.length);
    const surveyTypeId = surveytypeid.slice(surveytypeid.indexOf(":") + 1, surveytypeid.length);

    const [search, setSearch] = useState("");
    const SearchClick = (e) => {
      e.preventDefault();
      navigate(`/survey/survey-view/list-survey/page:1/search:${search}/sortclassroom:${classRoom}/subjectid:${subject}/surveytypeid:${surveyTypeId}`)
    };
    return (
        <div className='survey-view__top'>
            <div className="survey-view__top__title">
                {ArrayData.length === 0 ?null :
                <h5>اسم المادة : {ArrayData[0].subjectName}</h5>
                }
            </div>

            <div className="survey-view__top__filter">
                <div className="survey-view__top__search">
                <IconSearch SearchClick={SearchClick} />
                    <input type="text" placeholder='بحث' onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                            navigate(`/survey/survey-view/list-survey/page:1/search:${e.target.value}/sortclassroom:${classRoom}/subjectid:${subject}/surveytypeid:${surveyTypeId}`)                           
                            setLoading(false);
                        }
                        setSearch(e.target.value);
                    }} />
                </div>
            </div>
        </div >
    )
}

export default ListSurveyStudentsTop;