import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import { Api } from "../../../../api/index";
import { Headers } from "../../../../api/actions";
import Select from "react-dropdown-select";
import axiosInstance from "../../../../api/axios_instance";

function AddFormCardOne(props) {
  const {
    classRoom,
    setClassRoom,
    numberClassRoom,
    setNumberClassRoom,
    subject,
    setSubject,
    Day,
    setDay,
    testDuration,
    setTestDuration,
    startTime,
    setStartTime,
    Error,
    setError,
  } = props;

  const [codeClassRooms, setCodeClassRooms] = useState("");
  const [codeSubjects, setCodeSubjects] = useState("");
  useEffect(() => {
    setNumberClassRoom("");
  }, [classRoom]);
  useEffect(() => {
    setSubject("");
    setCodeSubjects("");
  }, [numberClassRoom]);
  return (
    <div className="exams-content-add__form__card">
      <h4 className="size-h4"> أولاً تفاصيل الاختبار</h4>
      <div className="exams-content-add__form__card__inputs">
        <InputDropdownClassRooms
          Value={classRoom}
          SetValue={setClassRoom}
          setCodeClassRooms={setCodeClassRooms}
          TitleData="اختر اسم الدورة"
          Error={Error}
          setError={setError}
        />
        {codeClassRooms === "" ? null : (
          <InputCode Value={codeClassRooms} TitleData="رمز الدورة" />
        )}

        {classRoom === "" ? null : (
          <InputDropdownNumberClassRoom
            key={classRoom + "InputDropdownNumberClassRoom"}
            codeClassRooms={codeClassRooms}
            Value={numberClassRoom}
            SetValue={setNumberClassRoom}
            setCodeSubjects={setCodeSubjects}
            TitleData="رقم الدورة"
            Error={Error}
            setError={setError}
          />
        )}
        {numberClassRoom === "" ? null : (
          <InputDropdownSubject
            key={numberClassRoom + "InputDropdownSubject"}
            numberClassRoom={numberClassRoom}
            Value={subject}
            SetValue={setSubject}
            setCodeSubjects={setCodeSubjects}
            TitleData="اختر اسم المادة"
            Error={Error}
            setError={setError}
          />
        )}
        {codeSubjects === "" ? null : (
          <InputCode
            object={classRoom}
            Value={codeSubjects}
            TitleData="رمز المادة"
          />
        )}

        <div className="exams-content-add__form__card__inputs__rows">
          <InputDatePicker
            Value={Day}
            setValue={setDay}
            Error={Error}
            setError={setError}
          />
          <InputTestDuration
            Value={testDuration}
            setValue={setTestDuration}
            Error={Error}
            setError={setError}
          />
        </div>
        <br />
        <div className="exams-content-add__form__card__inputs__rows">
          <InputTimeStart
            Value={startTime}
            setValue={setStartTime}
            Error={Error}
            setError={setError}
          />
          <div className="exams-content-add__form__card__inputs__rows__item"></div>
        </div>
      </div>
    </div>
  );
}

export default AddFormCardOne;

const InputTestDuration = (props) => {
  const { Value, setValue, Error, setError } = props;

  const OnChinge = (e) => {
    e.preventDefault();
    setValue(e.target.value);
    if (+e.target.value > 360)
      setError({
        ...Error,
        testDuration: "لا يمكن ان تزيد مدة الاختبار عن 6 ساعات (360 دقيقة)",
      });
    else if (+e.target.value <= 0)
      setError({
        ...Error,
        testDuration: "مدة الاختبار يجب ان تزيد عن الصفر",
      });
    else setError({ ...Error, testDuration: "" });
  };
  return (
    <div className="exams-content-add__form__card__inputs__rows__item">
      <label>مدة الاختبار</label>
      <div className="input-test-duration">
        <input
          type="number"
          placeholder="مدة الاختبار"
          value={Value}
          onChange={(e) => {
            OnChinge(e);
          }}
        />
        <span className="text">دقيقة</span>
      </div>
      {Error.testDuration && (
        <>
          <br />
          <span className="error-text">{Error.testDuration}</span>
          <br />
        </>
      )}
    </div>
  );
};
const InputDatePicker = (props) => {
  const { Value, setValue, Error, setError } = props;
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 5 && day !== 6;
  };
  return (
    <div className="exams-content-add__form__card__inputs__rows__item">
      <label>تاريخ الاختبار</label>
      <DatePicker
        selected={Value}
        onChange={(date) => {
          setValue(date);
          setError({ ...Error, day: "" });
        }}
        showMonthDropdown
        showYearDropdown
        showIcon
        filterDate={isWeekday}
        minDate={new Date()}
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" />
          </svg>
        }
      />
      {Error.day && (
        <>
          <br />
          <span className="error-text">{Error.day}</span>
          <br />
        </>
      )}
    </div>
  );
};
const InputTimeStart = (props) => {
  const { Value, setValue, PlaceHolder, Error, setError } = props;
  return (
    <div className="exams-content-add__form__card__inputs__rows__item">
      <label>موعد بداية الاختبار</label>
      <DatePicker
        selected={Value}
        onChange={(date) => {
          setValue(date);
          setError({ ...Error, startTime: "" });
        }}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={30}
        placeholderText={PlaceHolder}
        minTime={new Date(0, 0, 0, 8, 0)}
        maxTime={new Date(0, 0, 0, 20, 0)}
        timeCaption="Time"
        dateFormat="h:mm aa"
        showIcon
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
          </svg>
        }
      />
      {Error.startTime && (
        <>
          <br />
          <span className="error-text">{Error.startTime}</span>
          <br />
        </>
      )}
    </div>
  );
};
const InputDropdownClassRooms = (props) => {
  const { Value, SetValue, setCodeClassRooms, TitleData, Error, setError } =
    props;
  const [name, setName] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    GetDataClassRooms(setData, setLoading);
  }, []);

  if (isLoading === false)
    return (
      <div className="form-group">
        <label htmlFor=""> {TitleData}</label>
        <div className="btn-group">
          <button type="button" className={"btn  dropdown-toggle"}>
            <span className="size_body weight_500 text">{TitleData} </span>
            <Icon />
          </button>
        </div>
      </div>
    );

  return (
    <div className="form-group">
      <label htmlFor=""> {TitleData}</label>
      <div className="btn-group">
        <Select
          options={data}
          direction="rtl"
          onChange={(values) => {
            setName(values[0].label);
            SetValue(values[0].value);
            setCodeClassRooms(values[0].code);
            setError({ ...Error, classRoom: "" });
          }}
          placeholder={Value === "" ? TitleData : name}
          className="w-100 dropdown-toggle"
          classNamePrefix="react-select"
        />
      </div>
      {Error.classRoom && (
        <>
          <br />
          <span className="error-text">{Error.classRoom}</span>
          <br />
        </>
      )}
    </div>
  );
};
const InputCode = (props) => {
  const { Value, TitleData } = props;

  return (
    <div className="form-group">
      <label htmlFor=""> {TitleData}</label>
      <input type="text" className="form-control" value={Value} disabled />
    </div>
  );
};
const InputDropdownSubject = (props) => {
  const {
    numberClassRoom,
    Value,
    SetValue,
    setCodeSubjects,
    TitleData,
    Error,
    setError,
  } = props;
  const [name, setName] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    GetDataSubjectClassRooms(numberClassRoom, setData, setLoading);
  }, [numberClassRoom]);

  if (isLoading === false)
    return (
      <div className="form-group">
        <label htmlFor=""> {TitleData}</label>
        <div className="btn-group">
          <button type="button" className={"btn  dropdown-toggle"}>
            <span className="size_body weight_500 text">{TitleData} </span>

            <Icon />
          </button>
        </div>
      </div>
    );

  return (
    <div className="form-group">
      <label htmlFor=""> {TitleData}</label>
      <div className="btn-group">
        <Select
          options={data}
          direction="rtl"
          onChange={(values) => {
            if (values.length === 0) {
              SetValue([]);
            } else {
              SetValue(values[0].value);
              setName(values[0].label);
              setCodeSubjects(values[0].code);
            }
            setError({ ...Error, subject: "" });
          }}
          placeholder={Value === "" ? TitleData : name}
          className="w-100 dropdown-toggle"
          classNamePrefix="react-select"
        />
      </div>
      {Error.subject && (
        <>
          <br />
          <span className="error-text">{Error.subject}</span>
          <br />
        </>
      )}
    </div>
  );
};
export const InputDropdownNumberClassRoom = (props) => {
  const { codeClassRooms, Value, SetValue, TitleData, Error, setError } = props;
  const [name, setName] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    GetDataNumberClassRooms(codeClassRooms, setData, setLoading);
  }, [codeClassRooms]);
  useEffect(() => {
    SetValue("");
    setName("");
  }, [data]);

  if (isLoading === false)
    return (
      <div className="form-group">
        <label htmlFor=""> {TitleData}</label>
        <div className="btn-group">
          <button type="button" className={"btn  dropdown-toggle"}>
            <span className="size_body weight_500 text">{TitleData} </span>
            <Icon />
          </button>
        </div>
      </div>
    );

  return (
    <div className="form-group">
      <label htmlFor=""> {TitleData}</label>
      <div className="btn-group">
        <Select
          options={data}
          direction="rtl"
          onChange={(values) => {
            if (values.length === 0) {
              SetValue([]);
            } else {
              SetValue(values[0].value);
              setName(values[0].label);
            }

            setError({ ...Error, numberClassRoom: "" });
          }}
          placeholder={!Value ? TitleData : name}
          className="w-100 dropdown-toggle"
          classNamePrefix="react-select"
        />
      </div>
      {Error.numberClassRoom && (
        <>
          <br />
          <span className="error-text">{Error.numberClassRoom}</span>
          <br />
        </>
      )}
    </div>
  );
};

const GetDataClassRooms = async (setData, setLoading) => {
  const options = {
    method: "get",
    url: `${Api}ClassRoom/GetClassRoomsSelectList`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(
        response.data.responseObject.map((itm) => ({
          value: itm.id,
          label: itm.name,
          code: itm.code,
        }))
      );
      // for (let i = 0; i < response.data.responseObject.length; i++) {
      //   setData((oldArray) => [
      //     ...oldArray,
      //     {
      //       value: response.data.responseObject[i].id,
      //       label: response.data.responseObject[i].name,
      //       code: response.data.responseObject[i].code,
      //     },
      //   ]);
      // }
      // // remove duplicate values from array
      // setData((oldArray) =>
      //   oldArray.filter(
      //     (v, i, a) => a.findIndex((t) => t.value === v.value) === i
      //   )
      // );
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
const GetDataSubjectClassRooms = async (classRoom, setData, setLoading) => {
  var data = new FormData();
  data.append("id", classRoom);
  const options = {
    method: "post",
    url: `${Api}Subject/GetSubjectsSelectList`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      for (let i = 0; i < response.data.responseObject.length; i++) {
        setData((oldArray) => [
          ...oldArray,
          {
            value: response.data.responseObject[i].id,
            label: response.data.responseObject[i].name,
            code: response.data.responseObject[i].code,
          },
        ]);
      }
      // remove duplicate values from array
      setData((oldArray) =>
        oldArray.filter(
          (v, i, a) => a.findIndex((t) => t.value === v.value) === i
        )
      );
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};

const GetDataNumberClassRooms = async (codeClassRooms, setData, setLoading) => {
  var data = new FormData();
  data.append("id", codeClassRooms);
  const options = {
    method: "post",
    url: `${Api}ClassRoom/GetClassRoomNumbersSelectList`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(
        response.data.responseObject.map((itm) => ({
          value: itm.id,
          label: itm.name,
          code: itm.code,
        }))
      );
      // for (let i = 0; i < response.data.responseObject.length; i++) {
      //   setData((oldArray) => [
      //     ...oldArray,
      //     {
      //       value: response.data.responseObject[i].name,
      //       label: response.data.responseObject[i].name,
      //       code: response.data.responseObject[i].code,
      //     },
      //   ]);
      // }
      // // remove duplicate values from array
      // setData((oldArray) =>
      //   oldArray.filter(
      //     (v, i, a) => a.findIndex((t) => t.value === v.value) === i
      //   )
      // );
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
const Icon = () => {
  return (
    <span className="icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M6.5 9L12.5 15L18.5 9"
          stroke="#5E6366"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
