/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ModalAddModerators from "./modal-add-moderators";
import { Api } from "../../../api/index";
import { Headers, HeadersFile } from "../../../api/actions";
import axios from "axios";
import swal from "sweetalert";
import IconSearch from "../../../components/shared/icon-search";
import axiosInstance from "../../../api/axios_instance";

const ModeratorsTop = (props) => {
  const { setLoading, setSortRole, setLoadingRole, loadingRole, sortRole } =
    props;
  const { search } = useParams();
  const searchKeyword = search.slice(search.indexOf(":") + 1, search.length);
  let navigate = useNavigate();
  const [searchKey, setSearchKey] = useState(searchKeyword || "");
  const SearchClick = (e) => {
    e.preventDefault();
    navigate(`/moderators/page:${1}/search:${search}/sortrole:`);
  };
  return (
    <>
      <div className="add_data justify-content-end d-flex w-100">
        <button
          type="button"
          className="btn btn-primary-blue"
          data-bs-toggle="modal"
          data-bs-target="#ModalAddModerators"
        >
          <span className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M12.5 5V19"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.5 12H19.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span className="text">إضافة مشرف جديد</span>
        </button>
      </div>
      <div className="moderators-content__top">
        <div className="moderators-content__top__title">
          <h5>المشرفين</h5>
        </div>

        <div className="moderators-content__top__filter">
          <div className="input-group-search">
            <IconSearch SearchClick={SearchClick} />
            <input
              type="text"
              defaultValue={searchKey}
              placeholder="بحث"
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  navigate(
                    `/moderators/page:${1}/search:${e.target.value}/sortrole:`
                  );
                  setLoading(false);
                }
                setSearchKey(e.target.value);
              }}
            />
          </div>
          <MaterialsDropdown
            setSortRole={setSortRole}
            sortRole={sortRole}
            setLoadingRole={setLoadingRole}
            loadingRole={loadingRole}
          />

          <button
            type="button"
            className="btn btn-download"
            onClick={() => {
              setSortRole([]);
              navigate(`/moderators/page:${1}/search:${""}/sortrole:${""}`);
            }}
          >
            <span className="text">مسح الكل</span>
          </button>
          <button type="button" className="btn btn-download">
            <span className="icon">
              <img src="/assets/icons/icon-download.svg" alt="" />
            </span>
            <span className="text">تحميل قائمة المشرفين</span>

            <input
              type="file"
              accept=".xlsx, .xls, .csv"
              name="files"
              onChange={(e) => {
                SendFile(e.target.files[0]);
                e.target.value = "";
              }}
            />
          </button>
        </div>
      </div>
      <ModalAddModerators />
    </>
  );
};

export default ModeratorsTop;

const MaterialsDropdown = (props) => {
  const { setSortRole, setLoadingRole, loadingRole, sortRole } = props;
  const [selected, setSelected] = useState(sortRole ?? []);
  console.log(selected, sortRole);

  const handleChange = (event) => {
    const { value } = event.currentTarget;
    console.log(event.target.value);
    if (event.target.checked) {
      setSelected((prv) => [...prv, value]);
    } else {
      setSelected((prv) => prv.filter((option) => option !== value));
    }
  };
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    GetData(setData, setIsLoading);
  }, []);
  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <div className="d-flex">
          <span className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                d="M14.6668 2.5H1.3335L6.66683 8.80667V13.1667L9.3335 14.5V8.80667L14.6668 2.5Z"
                stroke="#53545C"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span className="text">تصفية</span>
        </div>
      </button>
      <ul className="dropdown-menu">
        <p>
          <span>تصفية حسب الصلاحية</span>
        </p>
        {isLoading ? (
          <li>Loading...</li>
        ) : (
          data.map((item, index) => {
            return (
              <li key={index}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={item.name}
                  id="flexCheckDefault"
                  name="flexCheckDefault"
                  onChange={handleChange}
                  checked={selected.includes(item.name)}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  <span>{item.name}</span>
                </label>
              </li>
            );
          })
        )}

        <button
          className="btn btn-sendapi"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            console.log(selected);
            setSortRole(selected);
            setLoadingRole(!loadingRole);
          }}
        >
          <span>تصفية</span>
        </button>
      </ul>
    </div>
  );
};

const GetData = async (setData, setIsLoading) => {
  const options = {
    method: "get",
    url: `${Api}Role/GetRolesSelectList`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setIsLoading(false);
    })
    .catch(function (error) {
      setIsLoading(false);
    });
};

const SendFile = async (file) => {
  console.log(file);
  var data = new FormData();
  data.append("ImportFile", file);

  const options = {
    method: "post",
    url: `${Api}ApplicationDbUser/ImportUsersBank`,
    headers: HeadersFile,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      console.log(response);
      swal("تم تحميل الملف بنجاح", {
        icon: "success",
        confirmButtonColor: "#d33",
        button: {
          text: "إغلاق",
          closeModal: true,
        },
      });
    })
    .catch(function (error) {});
};
