import React, { useEffect, useState } from "react";
import { Api } from "../../../api/index";
import axios from "axios";
import { Headers } from "../../../api/actions";
import Loading from "../../../components/loading/loading";

import NavbarLeft from "../../../components/navbar/navbar_left";
import NavbarTop from "../../../components/navbar/navbar_top";
import SurveyQuestionsList from "../component/survey-questions";
import { useParams } from "react-router";
import axiosInstance from "../../../api/axios_instance";

const SurveyQuestions = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetData(id, setData, setLoading);
  }, [id]);

  const ObJectArrayData = [
    {
      id: 2,
      href: "/survey",
      title: "الاستبيانات",
    },
  ];
  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <SurveyQuestionsList ArrayData={data} />
        </div>
      </div>
    </section>
  );
};

export default SurveyQuestions;

const GetData = async (id, setData, setLoading) => {
  var data = new FormData();
  data.append("id", id);

  const options = {
    method: "post",
    url: `${Api}Survey/GetSurveyQuestionsBySurveyType`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
