import React from 'react';
import QuestionTop from './question-top';
import QuestionTable from './question-table';

const QuestionContent = (props) => {
  const { ArrayData,perpage ,pagenumber,setLoading ,PageSize,setPageSize} = props;
  return (
    <div className='question-content'>
        <QuestionTop setLoading={setLoading} ArrayData={ArrayData}/>
        <QuestionTable  ArrayData={ArrayData} perpage={perpage} pagenumber={pagenumber} setLoading={setLoading} PageSize={PageSize} setPageSize={setPageSize} />
    </div>
  )
}

export default QuestionContent;


