import React from "react";
import ShowSurveyTop from "./show-survey-top";
import ShowSurveyTable from "./show-survey-table";

const ShowSurveyContent = (props) => {
  const { ArrayData, perpage, pagenumber, setLoading, PageSize, setPageSize } =
    props;
  return (
    <div className="survey-view">
      <ShowSurveyTop ArrayData={ArrayData} />
      <ShowSurveyTable
        ArrayData={ArrayData.items}
        perpage={perpage}
        pagenumber={pagenumber}
        setLoading={setLoading}
        PageSize={PageSize}
        setPageSize={setPageSize}
      />
    </div>
  );
};

export default ShowSurveyContent;
