import React  from 'react'
import { NavLink, useParams } from 'react-router-dom'

const ListStudentsTop = () => {
    const { id,subjectid, classroomid } = useParams();
    const Id = id.slice(id.indexOf(":") + 1, id.length);
    const subjectId = subjectid.slice(subjectid.indexOf(":") + 1, subjectid.length);
    const classroom = classroomid.slice(classroomid.indexOf(":") + 1, classroomid.length);

    return (<div className='exams-content__title '>
        <div className="add_data justify-content-end d-flex w-100">
            <NavLink className="btn btn-repetiton"  to={`/exams/repetiton/${Id}/subjectid:${subjectId}/classroomid:${classroom}`}>
                <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M12.5 5V19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.5 12H19.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>
                <span className="text">
                    إعادة الاختبار
                </span>
            </NavLink>
        </div>

        <br />
        <div className="d-flex justify-content-between align-items-center w-100">
            <h5 className='size-h5'>قائمة الطلاب</h5>
        </div>
    </div>
    )
}

export default ListStudentsTop
