import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { Api } from "../../../api/index.js";
import { Headers } from "../../../api/actions.js";
import Loading from "../../../components/loading/loading.jsx";

import NavbarLeft from "../../../components/navbar/navbar_left.jsx";
import NavbarTop from "../../../components/navbar/navbar_top.jsx";
import DeliveryContent from "../component/edite-question-content/delivery/index.jsx";
import axiosInstance from "../../../api/axios_instance.jsx";

const EditeQuestionMatching = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    GetData(id, setData, setLoading);
  }, [id]);

  const ObJectArrayData = [
    {
      id: 2,
      href: `/questionbanck/page:${1}/search:${""}/sortclassroom:${""}`,
      title: "بنك الاسئلة",
    },
  ];

  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <div className="add-question-content">
            <div className="add-question__top d-flex justify-content-between align-items-center w-100">
              <h5 className="size-h5"> عرض السؤال</h5>
            </div>
            <div className="add-question-content__card">
              <h6 className="mb-9">عرض السؤال</h6>
              <textarea
                rows="3"
                value={"يرجى التوصيل الاجابه الصحيحه بالسؤال "}
                placeholder="اكتب نص السؤال"
                name="add-question-textarea"
                required
                className={"form-control"}
                disabled
              ></textarea>
              <DeliveryContent Answers={data} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditeQuestionMatching;

const GetData = async (id, setData, setLoading) => {
  var data = new FormData();
  data.append("id", id);

  const options = {
    method: "post",
    url: `${Api}Question/GetMatchingQuestionByGroupId`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      //  console.log(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
