import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const DeliveryContent = (props) => {
  const { Answers } = props;

  const [answerAll, setAnswerAll] = useState([...Answers.matchingQuestions]);

  return (
    <>
      <SelectBoxTypeAnswer />
      <DeliveryCard Answers={answerAll} setAnswerAll={setAnswerAll} />
      {/* <ButtonsAll /> */}
    </>
  );
};

export default DeliveryContent;

const SelectBoxTypeAnswer = () => {
  return (
    <div className="select-box">
      <label htmlFor="">نوع السؤال</label>
      <select name="horns" id="horns" disabled>
        <option value={3}>توصيل</option>
      </select>
    </div>
  );
};

const DeliveryCard = (props) => {
  const { Answers, setAnswerAll } = props;

  return (
    <div className="delivery-row">
      <div className="delivery-row__list">
        <div className="delivery-row__left">
          <span className="title-sub"> العمود الأول</span>
          <span className="title-sub"> السؤال</span>
        </div>
        <div className="delivery-row__right">
          <span className="title-sub"> العمود الثاني</span>
          <span className="title-sub"> الأجابه</span>
        </div>
      </div>
      {Answers.map((item, index) => {
        return (
          <DeliveryCardList
            key={index}
            item={item}
            index={index}
            Answers={Answers}
            setAnswerAll={setAnswerAll}
          />
        );
      })}
      <div className="d-flex justify-content-between">
        <span
          style={{
            flex: "1",
          }}
        ></span>
        <span
          className="blue-text"
          style={{
            flex: "1",
          }}
        >
          سيتم ظهور الإجابات بشكل عشوائي للطلاب
        </span>
      </div>
    </div>
  );
};

const DeliveryCardList = (props) => {
  const { item, index, Answers, setAnswerAll } = props;

  const [question, setQuestion] = useState(item.questionName);
  const [answer, setAnswer] = useState(item.answerName);

  const ChingeQuestion = (e) => {
    e.preventDefault();
    setQuestion(e.target.value);
    Answers[index].questionName = e.target.value;
    setAnswerAll(Answers);
  };

  const ChingeAnswer = (e) => {
    e.preventDefault();
    setAnswer(e.target.value);
    Answers[index].answerName = e.target.value;
    setAnswerAll(Answers);
  };

  return (
    <div className="delivery-row__list">
      <div className="delivery-row__left">
        <div className="data-quection">
          <span className="number">
            <span>{index + 1}</span>
          </span>
          <input
            type="text"
            name=""
            id=""
            className="form-control"
            value={question}
            disabled
            onChange={ChingeQuestion}
          />
        </div>
      </div>
      <div className="delivery-row__right">
        <div className="data-quection">
          <span className="number">
            <span>{index + 1}</span>
          </span>
          <input
            type="text"
            name=""
            id=""
            className="form-control "
            value={answer}
            disabled
            onChange={ChingeAnswer}
          />
        </div>
      </div>
    </div>
  );
};

const ButtonsAll = () => {
  const navigate = useNavigate();

  return (
    <div className="add-question-content__card__buttons">
      <button
        type="button"
        className="btn btn-cansal"
        onClick={(e) => {
          e.preventDefault();
          navigate(-1);
        }}
      >
        <span>رجوع</span>
      </button>
    </div>
  );
};
