import React, { useState } from 'react'
import SubjectsTop from './subjects-top';
import SubjectsTable from './subjects-table';

const SubjectsContent = (props) => {
  const { ArrayData,perpage ,pagenumber,setLoading ,PageSize,setPageSize}= props;
  return (
    <div className='courses-content'>
        <SubjectsTop Title={ArrayData.length === 0 ?"": ArrayData[0].classRoomNameAr} setLoading={setLoading}/>
        <SubjectsTable ArrayData={ArrayData} perpage={perpage} pagenumber={pagenumber} setLoading={setLoading} 
        PageSize={PageSize} setPageSize={setPageSize}/>
    </div>
  )
}

export default SubjectsContent;

