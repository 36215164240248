import axios from "axios";
import { Api } from "../../../api/index.js";
import { Headers } from "../../../api/actions.js";
import ReactPaginate from "react-paginate";

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axiosInstance from "../../../api/axios_instance.jsx";

const PowersTable = (props) => {
  const { Arraydata } = props;

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemPerpage, setItemPerpage] = useState(10);
  const [loadingDeletData, setLoadingDeletData] = useState(false);

  useEffect(() => {
    const endOffest = itemOffset + itemPerpage;
    setCurrentItems(Arraydata.slice(itemOffset, endOffest));
    setPageCount(Math.ceil(Arraydata.length / itemPerpage));
  }, [itemOffset, itemPerpage, Arraydata]);

  const handlePageClick = (e) => {
    const newOffest = (e.selected * itemPerpage) % Arraydata.length;
    setItemOffset(newOffest);
  };
  return (
    <div className="table-container">
      <PowersTableHead />
      <>
        <ul className="table-container__body">
          {currentItems.map((item) => {
            return (
              <li key={item.id}>
                <PowersTableBody
                  Item={item}
                  loadingDeletData={loadingDeletData}
                  setLoadingDeletData={setLoadingDeletData}
                />
              </li>
            );
          })}
        </ul>

        <Paginate
          handlePageClick={handlePageClick}
          pageCount={pageCount}
          itemPerpage={itemPerpage}
          setItemPerpage={setItemPerpage}
        />
      </>
    </div>
  );
};

export default PowersTable;

const PowersTableHead = () => {
  return (
    <ul className="table-container__head">
      <li>
        <span className="justify-content-start">
          <label htmlFor="horns">كود الصلاحية</label>
        </span>
        <span>اسم الصلاحية</span>
        <span className="justify-content-end">الإجراءات</span>
      </li>
    </ul>
  );
};

const PowersTableBody = (props) => {
  const { Item, loadingDeletData, setLoadingDeletData } = props;
  return (
    <>
      <span className="justify-content-start">
        <label htmlFor="horns">{Item.code} </label>
      </span>
      <span>{Item.normalizedName}</span>
      <span className="justify-content-end">
        <NavLink to={`/powers/edit/${Item.id}`} className="btn btn-edit">
          <img src="/assets/icons/icon-edit.svg" alt="edit" />
        </NavLink>
        <button
          type="button"
          className="btn btn-remove"
          onClick={(e) => {
            DeletData(Item.id, loadingDeletData, setLoadingDeletData);
          }}
        >
          <img src="/assets/icons/icon-delete.svg" alt="remove" />
        </button>
      </span>
    </>
  );
};

const DeletData = async (id, loadingDeletData, setLoadingDeletData) => {
  var data = new FormData();
  data.append("id", id);
  const options = {
    method: "post",
    url: `${Api}Role/DeleteRole`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setLoadingDeletData(!loadingDeletData);
      if (response.responseStatus.description === "Success") {
      }
    })
    .catch(function (error) {});
};

const Paginate = (props) => {
  const { handlePageClick, pageCount } = props;
  return (
    <div className="paginate_control justify-content-end">
      <ReactPaginate
        previousLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9 18L15 12L9 6"
              stroke="#64748B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        nextLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 6L9 12L15 18"
              stroke="#94A3B8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        breakLabel={"..."}
        pageCount={pageCount}
        postsPerPage={2}
        pageSize={1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        renderOnZeroPageCount={null}
      />
    </div>
  );
};
