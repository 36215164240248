import React, { useEffect, useState } from "react";
import { Api } from "../../../api";
import axios from "axios";
import { Headers } from "../../../api/actions";
import Loading from "../../../components/loading/loading";

import NavbarLeft from "../../../components/navbar/navbar_left";
import NavbarTop from "../../../components/navbar/navbar_top";
import AddPowersContent from "../component/add-powers-content";
import axiosInstance from "../../../api/axios_instance";

const AddPowers = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    GetData(setData, setLoading);
  }, []);

  const ObJectArrayData = [
    {
      id: 2,
      href: `/powers`,
      title: "الصلاحيات",
    },
  ];
  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <AddPowersContent Arraydata={data} />
        </div>
      </div>
    </section>
  );
};

export default AddPowers;

const GetData = async (setData, setLoading) => {
  const options = {
    method: "get",
    url: `${Api}Claim/GetClaims`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
