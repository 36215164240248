import React from 'react'
import CoursesTop from './courses-top';
import CoursesTable from './courses-table';

const CoursesContent = (props) => {
  const { ArrayData,perpage ,pagenumber,setLoading ,PageSize,setPageSize} = props;
  return (
    <div className='courses-content'>
        <CoursesTop setLoading={setLoading} />
        <CoursesTable ArrayData={ArrayData} perpage={perpage} pagenumber={pagenumber} setLoading={setLoading} 
        PageSize={PageSize} setPageSize={setPageSize}/>
    </div>
  )
}

export default CoursesContent;

