/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Api } from "../../api";
import axios from "axios";
import { Headers } from "../../api/actions";
import Loading from "../../components/loading/loading";
import { useParams } from "react-router";

import NavbarLeft from "../../components/navbar/navbar_left";
import NavbarTop from "../../components/navbar/navbar_top";
import ModeratorsContent from "./component/moderators-content";
import axiosInstance from "../../api/axios_instance";

const Moderators = () => {
  const { page, search } = useParams();
  const pageCount = +page.slice(page.indexOf(":") + 1, page.length);
  const searchKeyword = search.slice(search.indexOf(":") + 1, search.length);
  const [sortRole, setSortRole] = useState([]);
  const [loadingRole, setLoadingRole] = useState(false);
  const [perpage, setPerpage] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [PageSize, setPageSize] = useState(10);

  useEffect(() => {
    GetData(
      setData,
      setLoading,
      pageCount,
      PageSize,
      searchKeyword,
      sortRole,
      setPerpage
    );
  }, [pageCount, searchKeyword, sortRole, PageSize]);

  useEffect(() => {
    GetDataRole(
      setData,
      setLoadingRole,
      pageCount,
      PageSize,
      searchKeyword,
      sortRole,
      setPerpage
    );
  }, [loadingRole]);

  const ObJectArrayData = [
    {
      id: 2,
      href: `/moderators/page:${1}/search:${""}/sortrole:${""}`,
      title: "المشرفين",
    },
  ];
  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <ModeratorsContent
            ArrayData={data}
            perpage={perpage}
            pagenumber={pageCount}
            setLoading={setLoading}
            PageSize={PageSize}
            setPageSize={setPageSize}
            setSortRole={setSortRole}
            sortRole={sortRole}
            setLoadingRole={setLoadingRole}
            loadingRole={loadingRole}
          />
        </div>
      </div>
    </section>
  );
};

export default Moderators;

const GetData = async (
  setData,
  setLoading,
  pageCount,
  PageSize,
  searchKeyword,
  sortRole,
  setPerpage
) => {
  setLoading(false);
  var data = new FormData();
  data.append("pageNumber", pageCount);
  data.append("searchKeyword", searchKeyword);
  if (sortRole.length !== 0) {
    for (let i = 0; i < sortRole.length; i++) {
      data.append(`roleName[${i}]`, sortRole[i]);
    }
  }
  data.append("pageSize", PageSize);

  const options = {
    method: "post",
    url: `${Api}ApplicationDbUser/SearchForUsers`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.items);
      setLoading(true);

      setPerpage(
        Math.ceil(
          response.data.responseObject.totalItemsCount /
            response.data.responseObject.pageSize
        )
      );
    })
    .catch(function (error) {
      setLoading(true);
    });
};

const GetDataRole = async (
  setData,
  setLoadingRole,
  pageCount,
  PageSize,
  searchKeyword,
  sortRole,
  setPerpage
) => {
  var data = new FormData();
  data.append("pageNumber", pageCount);
  data.append("searchKeyword", searchKeyword);
  if (sortRole.length !== 0) {
    for (let i = 0; i < sortRole.length; i++) {
      data.append(`roleName[${i}]`, sortRole[i]);
    }
  }
  data.append("pageSize", PageSize);

  const options = {
    method: "post",
    url: `${Api}ApplicationDbUser/SearchForUsers`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setLoadingRole(true);
      setData(response.data.responseObject.items);
      setPerpage(
        Math.ceil(
          response.data.responseObject.totalItemsCount /
            response.data.responseObject.pageSize
        )
      );
    })
    .catch(function (error) {
      setLoadingRole(true);
    });
};
