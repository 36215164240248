import React from 'react'
import MaterialExamTop from './material-exam-top';
import MaterialExamRows from './material-exam-rows';

const MaterialExamContent = (props) => {
  const {Arraydata} = props;
  return (
    <div className='materials-exam-content'>
        <MaterialExamTop Arraydata={Arraydata}/>
        <MaterialExamRows Arraydata={Arraydata}/>
    </div>
  )
}

export default MaterialExamContent;

