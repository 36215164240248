import React, { useEffect, useState } from "react";
import { Api } from "../../../api";
import { Headers } from "../../../api/actions";
import axios from "axios";
import Loading from "../../../components/loading/loading";
import ChartsContent from "../../../components/home-sections/survey-chart-sections/charts-content";
import axiosInstance from "../../../api/axios_instance";

const SurveysChart = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [surveyTypeId, setSurveyTypeId] = useState(null);
  const [classRoomCode, setClassRoomCode] = useState(null);
  const [classRoomNumber, setClassRoomNumber] = useState(null);
  const [classRoomName, setClassRoomName] = useState(null);

  useEffect(() => {
    GetData(
      setData,
      setLoading,
      surveyTypeId,
      classRoomNumber,
      classRoomCode,
      classRoomName
    );
  }, [surveyTypeId, classRoomNumber, classRoomCode, classRoomName]);

  if (!loading) {
    return (
      <>
        <Loading />
      </>
    );
  }
  return (
    <>
      <ChartsContent
        Arraydata={data}
        surveyTypeId={surveyTypeId}
        setSurveyTypeId={setSurveyTypeId}
        classRoomCode={classRoomCode}
        setClassRoomCode={setClassRoomCode}
        classRoomNumber={classRoomNumber}
        setClassRoomNumber={setClassRoomNumber}
        classRoomName={classRoomName}
        setClassRoomName={setClassRoomName}
      />
    </>
  );
};

export default SurveysChart;

const GetData = async (
  setData,
  setLoading,
  surveyTypeId,
  classRoomNumber,
  classRoomCode,
  classRoomName
) => {
  const options = {
    method: "post",
    url: `${Api}Charts/SurveyChart`,
    headers: Headers,
    data: {
      surveyTypeId: surveyTypeId === null ? 0 : surveyTypeId,
      classRoomNumber: classRoomNumber === null ? "" : classRoomNumber,
      classRoomCode: classRoomCode === null ? "" : classRoomCode,
      classRoomName: classRoomName === null ? "" : classRoomCode,
      subjectCode: "",
      subjectName: "",
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
/*
survey-chart-sections
ClassRoom
TestChart
StudentChart
student-chart-sections
test-chart-sections
classRoom-chart-sections

*/
