import React from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";

const MaterialsSubjectTable = (props) => {
  const { ArrayData, perpage, pagenumber, setLoading, PageSize, setPageSize } = props;
  const { page, search, subjectId, classroomId } = useParams();
  const searchKeyword = search.slice(search.indexOf(":") + 1, search.length);
  const sortSubject = subjectId.slice(subjectId.indexOf(":") + 1, subjectId.length);
  const sortClassRoom = classroomId.slice(classroomId.indexOf(":") + 1, classroomId.length);
  
  let navigate = useNavigate();

  const handlePageClick = (data) => {
    let number = 1 + data.selected;
    navigate(`/materials/material-subject/page:${number}/search:${searchKeyword}/subjectId:${sortSubject}/classroomId:${sortClassRoom}`);
    setLoading(false);
  };


  return (
    <div className="table-container">
      <MaterialsSubjectTableHead />
      <>
        <ul className="table-container__body">
          {ArrayData.map((item, index) => {
            return (
              <li key={index}>
                <MaterialsSubjectTableBody Item={item} />
              </li>
            );
          })}
        </ul>
        <Paginate handlePageClick={handlePageClick} perpage={perpage} pagenumber={pagenumber}
          setLoading={setLoading} PageSize={PageSize} setPageSize={setPageSize} />
      </>
    </div>
  );
};

export default MaterialsSubjectTable;

const MaterialsSubjectTableHead = () => {
  return (
    <ul className="table-container__head">
      <li>
        <span className="justify-content-start">اسم الطالب</span>
        <span>رقم الهوية</span>
        <span>تاريخ الاختبار</span>
        <span>درجة الاختبار</span>
        <span className="justify-content-end">الإجراءات</span>
      </li>
    </ul>
  );
};

const MaterialsSubjectTableBody = (props) => {
  const { Item } = props;
  return (
    <>
      <span className="justify-content-start">{Item.studentName} </span>
      <span>{Item.nationalId} </span>
      <span> {Item.testDate}</span>
      <span> {Item.studentDegree} / 100</span>
      <span className="link-list justify-content-end">
        <NavLink to={`/materials/material-exam/testid:${Item.testId}/studentid:${Item.studentId}`}>
          <span>عرض الاختبار</span>
        </NavLink>
      </span>
    </>
  );
};

function Paginate(props) {
  const { handlePageClick, perpage, pagenumber, setLoading, PageSize, setPageSize } = props;
  const SelectValue = [
    {
      id: 1,
      value: 10,
      title: "10 صفوف",
    },
    {
      id: 2,
      value: 20,
      title: "20 صفوف",
    },
    {
      id: 3,
      value: 30,
      title: "30 صفوف",
    },
    {
      id: 4,
      value: 40,
      title: "40 صفوف",
    },
    {
      id: 5,
      value: 50,
      title: "50 صفوف",
    },
  ]

  return (
    <div className='paginate_control'>
      <div className="paginate_control__select">
        <label htmlFor=""><span>عدد الصفوف</span></label>
        <select className='form-select' onChange={(e) => {
          setPageSize(parseInt(e.target.value))
          setLoading(false);
        }} value={PageSize}>
          <>
            {SelectValue.map((item, index) => {
              return (
                <option key={index} value={item.value}>{item.title}</option>
              )
            })}
          </>
        </select>
      </div>
      <ReactPaginate
        previousLabel={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M9 18L15 12L9 6" stroke="#64748B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>}
        nextLabel=
        {<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M15 6L9 12L15 18" stroke="#94A3B8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>}
        breakLabel={"..."}
        pageCount={perpage}
        postsPerPage={2}
        pageSize={1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        renderOnZeroPageCount={null}
        forcePage={pagenumber - 1}
      />
    </div>
  )
}