import React, { useEffect, useState } from "react";
import { Api } from "../../../api";
import axios from "axios";

import { useNavigate, useParams } from "react-router";
import IconSearch from "../../../components/shared/icon-search";
import { Headers } from "../../../api/actions";
import axiosInstance from "../../../api/axios_instance";
import { FilterReset } from "../../../components/shared/reset-filter";

const StudentsTop = (props) => {
  const { setLoading, classRoomName, classRoomNumber } = props;
  const { sortclasscode, search: searchKey } = useParams();
  const classcode = sortclasscode.slice(
    sortclasscode.indexOf(":") + 1,
    sortclasscode.length
  );
  const searchVal = searchKey.split(":")[1];
  let navigate = useNavigate();
  const [search, setSearch] = useState(searchVal);
  const [codeClassRooms, setCodeClassRooms] = useState(classcode || "");

  const SearchClick = (e) => {
    e.preventDefault();
    navigate(
      `/students/page:${1}/search:${search}/sortclassroom:${classRoomName}/sortclasscode:${classcode}/sortclassroomnamber:${classRoomNumber}`
    );
  };
  const resetFilters = () => {
    navigate(
      `/students/page:${1}/search:/sortclassroom:/sortclasscode:/sortclassroomnamber:`
    );
  };
  return (
    <div className="students-content__top">
      <div className="students-content__top__title">
        <h5>قائمة الطلاب</h5>
      </div>

      <div className="students-content__top__filter">
        <div className="students-content__top__search">
          <IconSearch SearchClick={SearchClick} />
          <input
            type="text"
            placeholder="بحث"
            defaultValue={search}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                navigate(
                  `/students/page:${1}/search:${
                    e.target.value
                  }/sortclassroom:${classRoomName}/sortclasscode:${classcode}/sortclassroomnamber:${classRoomNumber}`
                );
                setLoading(false);
              }
              setSearch(e.target.value);
            }}
          />
        </div>
        <MaterialsDropdownRoomName
          classRoomName={classRoomName}
          classRoomNumber={classRoomNumber}
          setCodeClassRooms={setCodeClassRooms}
          codeClassRooms={codeClassRooms}
        />

        <MaterialsDropdownRoomNamber
          classRoomName={classRoomName}
          codeClassRooms={codeClassRooms}
          disabled={classcode === ""}
        />
        {(classcode || searchVal || classRoomName || classRoomNumber) && (
          <FilterReset resetFilters={resetFilters} />
        )}
      </div>
    </div>
  );
};

export default StudentsTop;

const MaterialsDropdownRoomName = (props) => {
  const { classRoomName, classRoomNumber, setCodeClassRooms, codeClassRooms } =
    props;
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    const { value } = event.currentTarget;
    setValue(value);
  };

  useEffect(() => {
    GetData(setData, setLoading);
  }, []);

  if (!loading) {
    return <div className="dropdown"></div>;
  }
  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M14.6668 2.5H1.3335L6.66683 8.80667V13.1667L9.3335 14.5V8.80667L14.6668 2.5Z"
              stroke="#53545C"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span className="text"> تصفية حسب أسم الدورة</span>
      </button>
      <ul className="dropdown-menu">
        <p>
          <span>تصفية حسب أسم الدورة </span>
        </p>
        {data.map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="radio"
                id="flexCheckDefault"
                name={"sortclassroom2"}
                value={item.name}
                onChange={(e) => {
                  handleChange(e);
                  setCodeClassRooms(item.code);
                }}
                checked={item.name == classRoomName ? true : null}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>{item.name}</span>
              </label>
            </li>
          );
        })}
        <button
          type="button"
          className="btn-sendapi"
          onClick={(e) => {
            e.preventDefault();
            navigate(
              `/students/page:${1}/search:${""}/sortclassroom:${value}/sortclasscode:${codeClassRooms}/sortclassroomnamber:${classRoomNumber}`
            );
          }}
        >
          <span>تصفية</span>
        </button>
      </ul>
    </div>
  );
};
const MaterialsDropdownRoomNamber = (props) => {
  const { classRoomName, codeClassRooms, disabled } = props;
  let navigate = useNavigate();
  const { sortclassroomnamber } = useParams();
  const classRoomNumber = sortclassroomnamber.split(":")[1];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(classRoomNumber ?? "");

  const handleChange = (event) => {
    const { name } = event;
    setValue(name);
  };

  useEffect(() => {
    GetDataNumberClassRooms(codeClassRooms, setData, setLoading);
  }, [codeClassRooms]);

  if (!loading) {
    return <div className="dropdown"></div>;
  }
  console.log(value, data);
  return (
    <div className="dropdown" style={{ opacity: disabled ? ".5" : "1" }}>
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        disabled={disabled}
      >
        <span className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M14.6668 2.5H1.3335L6.66683 8.80667V13.1667L9.3335 14.5V8.80667L14.6668 2.5Z"
              stroke="#53545C"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span className="text"> تصفية حسب رقم الدورة</span>
      </button>
      <ul className="dropdown-menu">
        <p>
          <span>تصفية حسب رقم الدورة </span>
        </p>
        {data.map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="radio"
                id="flexCheckDefault"
                name={"sortclassroom"}
                value={item.name}
                onChange={() => handleChange(item)}
                checked={item.name === value}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>{item.name}</span>
              </label>
            </li>
          );
        })}
        <button
          type="button"
          className="btn-sendapi"
          onClick={(e) => {
            e.preventDefault();
            navigate(
              `/students/page:${1}/search:${""}/sortclassroom:${classRoomName}/sortclasscode:${codeClassRooms}/sortclassroomnamber:${value}`
            );
          }}
        >
          <span>تصفية</span>
        </button>
      </ul>
    </div>
  );
};
const GetData = async (setData, setLoading) => {
  setLoading(false);

  const options = {
    method: "GET",
    url: `${Api}ClassRoom/GetClassRoomsSelectList`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};

const GetDataNumberClassRooms = async (codeClassRooms, setData, setLoading) => {
  var data = new FormData();
  data.append("id", codeClassRooms);
  const options = {
    method: "post",
    url: `${Api}ClassRoom/GetClassRoomNumbersSelectList`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
