/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Headers } from "../../../../api/actions";
import { Api } from "../../../../api";
import axios from "axios";
import axiosInstance from "../../../../api/axios_instance";

const ShowSurveyTop = (props) => {
  const { ArrayData } = props;
  const { studentid } = useParams();
  const studentId = studentid.slice(
    studentid.indexOf(":") + 1,
    studentid.length
  );

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    GetData(studentId, setName, setLoading);
  }, []);

  if (!loading) {
    return (
      <div className="survey-view__top">
        <div className="survey-view__top__title">
          <h5>استبيان : </h5>

          <h5>اسم الطالب : </h5>
        </div>
      </div>
    );
  }

  return (
    <div className="survey-view__top">
      <div className="survey-view__top__title">
        <h5>
          استبيان : <span>{ArrayData.subjectName}</span>
        </h5>

        <h5>
          اسم الطالب : <span>{name}</span>
        </h5>
      </div>
    </div>
  );
};

export default ShowSurveyTop;

const GetData = async (studentId, setName, setLoading) => {
  var data = new FormData();
  data.append("pageNumber", 1);
  data.append("searchKeyword", "");
  data.append("pageSize", 10);
  const options = {
    method: "post",
    url: `${Api}Student/SearchForStudents`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      const Array = response.data.responseObject.items;
      for (let i = 0; i < Array.length; i++) {
        if (Array[i].id == studentId) {
          setName(Array[i].nameAr);
        }
      }

      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
