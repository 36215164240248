/* eslint-disable eqeqeq */
import React, { useEffect } from "react";

function AddFormCardTwo(props) {
  const {
    numberDelivery,
    setNumberDelivery,
    numberMultipleChoice,
    setMultipleChoice,
    numberTrueAndFalse,
    setNumberTrueAndFalse,

    degreesDelivery,
    setDegreesDelivery,
    degreesMultipleChoice,
    setDegreesMultipleChoice,
    degreesTrueAndFalse,
    setDegreesTrueAndFalse,

    isRandomQuestions,
    setIsRandomQuestions,
    setArrayQuestions,
    arrayQuestions,
    Error,
    setError,

    matchingCount,
    multiChoiceCount,
    trueAndFalseCount,
  } = props;

  return (
    <div className="exams-content-add__form__card">
      <h4 className="size-h4">ثانيا اختيار الاسئلة</h4>
      <div className="exams-content-add__form__card__check-box">
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="RandomQuestions"
            id="flexRadioDefault1"
            value={false}
            checked={isRandomQuestions === false ? true : false}
            onChange={(e) => {
              setIsRandomQuestions(false);
              setArrayQuestions([]);
              setNumberDelivery(0);
              setNumberTrueAndFalse(0);
              setMultipleChoice(0);
              setError((prv) => ({ ...prv, numberOfDegrees: "" }));
            }}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault1">
            أختيار الأسئلة بشكل يدوي
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="RandomQuestions"
            id="flexRadioDefault2"
            value={true}
            checked={isRandomQuestions === true ? true : false}
            onChange={(e) => {
              setIsRandomQuestions(true);
              setArrayQuestions([]);
              setNumberDelivery(0);
              setNumberTrueAndFalse(0);
              setMultipleChoice(0);
              setError((prv) => ({ ...prv, numberOfDegrees: "" }));
            }}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault2">
            أختيار عشوائي من الأسئلة الموجودة
          </label>
        </div>
      </div>

      <div className="exams-content-add__form__card__check-box-inputs">
        <h6>أدخل فى كل خانة عدد الاسئله المطلوبه فى كل نوع </h6>
        <div className="exams-content-add__form__card__check-box-inputs__rows">
          <InputNumberChoice
            Value={numberDelivery}
            setValue={setNumberDelivery}
            Label={"توصيل"}
            Error={Error}
            setError={setError}
            isRandomQuestions={isRandomQuestions}
            maxCount={matchingCount}
            selectedQuestionsNumber={
              arrayQuestions.filter((q) => q.questionTypeId === 3).length
            }
          />
          <InputNumberChoice
            Value={numberMultipleChoice}
            setValue={setMultipleChoice}
            Label={"أختيار من متعدد"}
            Error={Error}
            setError={setError}
            isRandomQuestions={isRandomQuestions}
            maxCount={multiChoiceCount}
            selectedQuestionsNumber={
              arrayQuestions.filter((q) => q.questionTypeId === 1).length
            }
          />
          <InputNumberChoice
            Value={numberTrueAndFalse}
            setValue={setNumberTrueAndFalse}
            Label={"صح وخطأ"}
            Error={Error}
            setError={setError}
            isRandomQuestions={isRandomQuestions}
            maxCount={trueAndFalseCount}
            selectedQuestionsNumber={
              arrayQuestions.filter((q) => q.questionTypeId === 2).length
            }
          />
        </div>
        {Error.numberOfDegrees && (
          <>
            <span className="error-text">{Error.numberOfDegrees}</span>
          </>
        )}
      </div>

      <div className="exams-content-add__form__card__check-box-inputs">
        <h6>أدخل فى كل خانة عدد الدرجات لكل سؤال حسب النوع</h6>
        <div className="exams-content-add__form__card__check-box-inputs__rows">
          <InputNumberDegreesChoice
            Value={degreesDelivery}
            setValue={setDegreesDelivery}
            Label={"توصيل"}
            Error={Error}
            setError={setError}
            maxCount={100}
          />
          <InputNumberDegreesChoice
            Value={degreesMultipleChoice}
            setValue={setDegreesMultipleChoice}
            Label={"أختيار من متعدد"}
            Error={Error}
            setError={setError}
            maxCount={100}
          />
          <InputNumberDegreesChoice
            Value={degreesTrueAndFalse}
            setValue={setDegreesTrueAndFalse}
            Label={"صح وخطأ"}
            Error={Error}
            setError={setError}
            maxCount={100}
          />
        </div>
        {Error.numberQuen && (
          <>
            <br />
            <span className="error-text">{Error.numberQuen}</span>
            <br />
          </>
        )}
      </div>
    </div>
  );
}

export default AddFormCardTwo;

export const InputNumberChoice = (props) => {
  const {
    Value,
    setValue,
    Label,
    Error,
    setError,
    isRandomQuestions,
    maxCount,
    selectedQuestionsNumber,
  } = props;
  useEffect(() => {
    if (Value > maxCount) {
      setError((prv) => ({
        ...prv,
        numberOfDegrees: `${
          maxCount == 0
            ? `لا يوجد أسئله من نوع ${Label}`
            : `يجب ان يكون اقل من ${maxCount + 1}`
        }`,
      }));
    }
  }, [Label, Value, maxCount, setError]);
  return (
    <div className="exams-content-add__form__card__check-box-inputs__rows__input">
      <label>{`عدد اسئله من نوع ${Label}`}</label>
      <div className="row-inputs">
        <span className="value">
          <input
            type="number"
            style={{
              background: "transparent",
              border: "none",
              outline: "none",
            }}
            className="value"
            value={
              isRandomQuestions === false ? selectedQuestionsNumber : Value
            }
            disabled={isRandomQuestions === false ? true : false}
            onChange={(e) => {
              const newVal = Number(e.target.value);
              if (isNaN(newVal)) {
                setError((prv) => ({
                  ...prv,
                  numberOfDegrees: `الرجاء ادخال عدد صحيح`,
                }));
              } else if (newVal > maxCount) {
                setError({
                  ...Error,
                  numberOfDegrees: `${
                    maxCount == 0
                      ? `لا يوجد أسئله من نوع ${Label}`
                      : `يجب ان يكون اقل من ${maxCount + 1}`
                  }`,
                });
              } else {
                setError({ ...Error, numberOfDegrees: "" });
                setValue(newVal);
              }
            }}
          />
          {/* {isRandomQuestions === false ? maxCount : Value} */}
        </span>
        <span className="buttons">
          <button
            disabled={isRandomQuestions === false ? true : false}
            className="btn button-next"
            onClick={() => {
              if (Value >= maxCount) {
                setError({
                  ...Error,
                  numberOfDegrees: `${
                    maxCount == 0
                      ? `لا يوجد أسئله من نوع ${Label}`
                      : `يجب ان يكون اقل من ${maxCount + 1}`
                  }`,
                });
              } else {
                setError({ ...Error, numberOfDegrees: "" });
                setValue(Value + 1);
              }
            }}
          >
            <img src="/assets/icons/icon-next.png" alt="" />
          </button>
          <button
            disabled={isRandomQuestions === false ? true : false}
            className="btn button-back"
            onClick={() => {
              if (Value < 1) {
                return false;
              } else {
                setError({ ...Error, numberOfDegrees: "" });
                setValue(Value - 1);
              }
            }}
          >
            <img src="/assets/icons/icon-back.png" alt="" />
          </button>
        </span>
      </div>
    </div>
  );
};

const InputNumberDegreesChoice = (props) => {
  const {
    Value,
    setValue,
    Label,
    Error,
    setError,
    isRandomQuestions,
    maxCount,
  } = props;
  return (
    <div className="exams-content-add__form__card__check-box-inputs__rows__input">
      <label>{Label}</label>
      <div className="input-number">
        <input
          type="number"
          name=""
          id=""
          min="0"
          max={maxCount}
          value={Value}
          disabled={isRandomQuestions === false ? true : false}
          onInput={(e) => {
            if (e.target.value.length > maxCount) {
            }
          }}
          onChange={(e) => {
            if (e.target.value > maxCount) {
              setError({
                ...Error,
                numberOfDegrees: `يجب ان يكون اقل من ${maxCount + 1}`,
              });
            } else {
              setError({ ...Error, numberOfDegrees: "" });
              setValue(e.target.value);
            }
          }}
          pattern={`[0-9]{3}-[0-9]{3}-[0-9]{4}`}
        />
      </div>
    </div>
  );
};
