import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../features/user/userSlice";

const NavbarLeft = () => {
  const [show, setShow] = useState(false);
  const menu = useSelector((state) => state.user.menu);

  const ObjectData = [
    {
      id: 1,
      href: "/",
      title: "الرئيسية",
      icon: "/assets/icons/navbar/icon-home.svg",
    },
    {
      id: 2,
      href: `/courses/page:${1}/search:${""}/active:${""}`,
      check: "ClassRoomsSearch",
      title: "الدورات",
      icon: "/assets/icons/navbar/icon-courses.svg",
    },
    {
      id: 3,
      href: `/materials/page:${1}/search:${""}/sortclassroom:${""}`,
      check: "SearchForSubjects",
      title: "المواد التعليمية",
      icon: "/assets/icons/navbar/icon-materials.svg",
    },
    {
      id: 4,
      href: `/questionbanck/page:${1}/search:${""}/sortclassroom:${""}`,
      check: "QuestionsBank",
      title: "بنك الاسئلة",
      icon: "/assets/icons/navbar/icon-question.svg",
    },
    {
      id: 5,
      href: `/exams/page:${1}/search:${""}`,
      check: "SearchForTests",
      title: "الأختبارات",
      icon: "/assets/icons/navbar/icon-the-exams.svg",
    },
    {
      id: 6,
      href: `/students/page:${1}/search:${""}/sortclassroom:${""}/sortclasscode:${""}/sortclassroomnamber:${""}`,
      check: "SearchForStudents",
      title: "قائمة الطلاب",
      icon: "/assets/icons/navbar/icon-students.svg",
    },
    {
      id: 7,
      href: `/survey`,
      check: "SearchSurvey",
      title: "الاستبيانات",
      icon: "/assets/icons/navbar/icon-questionnaires.svg",
    },
    {
      id: 8,
      href: `/moderators/page:${1}/search:${""}/sortrole:${""}`,
      check: "SearchForUsers",
      title: "المشرفين",
      icon: "/assets/icons/navbar/icon-moderators.svg",
    },
    {
      id: 9,
      href: `/powers/search:${""}`,
      check: "SearchForRoles",
      title: "الصلاحيات",
      icon: "/assets/icons/navbar/icon-powers.svg",
    },
    {
      id: 10,
      href: "/profile-personly",
      title: "الملف الشخصي",
      icon: "/assets/icons/navbar/icon-setting.svg",
    },
  ];
  return (
    <section
      className={show === false ? "navbar__left" : "navbar__left show-navbar"}
    >
      <div>
        <NavLink to={"/"} className="navbar__left__logo">
          <img src="/assets/images/logo-light.jfif" alt="logo" />
        </NavLink>
        <NavbarLeftList
          ObjectData={ObjectData.filter(
            (itm) => !itm.check || menu.includes(itm.check)
          )}
        />
      </div>
      <ButtonLogout />
      <button
        type="button"
        className="btn btn-open-navbar"
        onClick={(e) => {
          e.preventDefault();
          setShow(!show);
        }}
      >
        <span className="icon">
          <img src="/assets/icons/icon-open-navbar.png" alt="arrow-left" />
        </span>
      </button>
    </section>
  );
};

export default NavbarLeft;

const NavbarLeftList = (props) => {
  const { ObjectData } = props;
  let location = useLocation();
  const pathname = location.pathname;
  return (
    <ul>
      {ObjectData.map((item, index) => {
        return (
          <li key={index}>
            <NavLink
              to={item.href}
              className={
                pathname.includes(item.check) === true
                  ? "active nav-link"
                  : "nav-link"
              }
            >
              <span className="icon">
                <img src={item.icon} alt={item.title} />
              </span>
              <span className="title">{item.title}</span>
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

const ButtonLogout = () => {
  const [removeCookie] = useCookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="logout">
      <button
        className="btn"
        type="button"
        onClick={(e) => {
          e.preventDefault();
          dispatch(signOut());
          navigate("/signin");
        }}
      >
        <span className="icon">
          <img src="/assets/icons/navbar/icon-logout.svg" alt="logout" />
        </span>
        <span className="title">تسجيل الخروج</span>
      </button>
    </div>
  );
};
