import React, { useEffect, useState } from "react";
import axios from "axios";
import { Api } from "../../../api/index.js";
import { Headers } from "../../../api/actions.js";
import Loading from "../../../components/loading/loading";

import NavbarLeft from "../../../components/navbar/navbar_left";
import NavbarTop from "../../../components/navbar/navbar_top";
import { useParams } from "react-router";
import SurveyViewContent from "../component/survey-view";
import axiosInstance from "../../../api/axios_instance.jsx";

const SurveyView = () => {
  const { id, page, search, sortclassroom } = useParams();

  const pageCount = page.slice(page.indexOf(":") + 1, page.length);
  const searchKeyword = search.slice(search.indexOf(":") + 1, search.length);
  const classRoom = sortclassroom.slice(
    sortclassroom.indexOf(":") + 1,
    sortclassroom.length
  );
  const subject = "";
  const [startDay, setStartDay] = useState(null);
  const [endDay, setEndDay] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perpage, setPerpage] = useState(0);
  const [PageSize, setPageSize] = useState(10);

  const ObJectArrayData = [
    {
      id: 2,
      href: `/survey`,
      title: "الاستبيانات",
    },
  ];

  useEffect(() => {
    GetData(
      id,
      pageCount,
      searchKeyword,
      classRoom,
      subject,
      startDay,
      endDay,
      PageSize,
      setPerpage,
      setData,
      setLoading
    );
  }, [
    pageCount,
    PageSize,
    searchKeyword,
    classRoom,
    subject,
    startDay,
    endDay,
    id,
  ]);

  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <SurveyViewContent
            ArrayData={data}
            setStartDay={setStartDay}
            setEndDay={setEndDay}
            perpage={perpage}
            pagenumber={pageCount}
            setLoading={setLoading}
            PageSize={PageSize}
            setPageSize={setPageSize}
            classRoomID={classRoom}
          />
        </div>
      </div>
    </section>
  );
};

export default SurveyView;

const GetData = async (
  id,
  pageCount,
  searchKeyword,
  classRoom,
  subject,
  startDay,
  endDay,
  PageSize,
  setPerpage,
  setData,
  setLoading
) => {
  const options = {
    method: "post",
    url: `${Api}Survey/SearchForSurvey`,
    headers: Headers,
    data: {
      pageNumber: pageCount,
      surveyTypeId: id,
      searchKeyword: searchKeyword,
      classRoomId: classRoom === "" ? null : classRoom,
      subjectId: subject === "" ? null : subject,
      fromDate: startDay,
      toDate: endDay,
      pageSize: PageSize,
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.items);
      console.log(response.data.responseObject.items);
      setLoading(true);

      setPerpage(
        Math.ceil(
          response.data.responseObject.totalItemsCount /
            response.data.responseObject.pageSize
        )
      );
    })
    .catch(function (error) {
      setLoading(true);
    });
};
