import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

function AuthGuard({ children }) {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!user.token && location.pathname !== "/signin") navigate("/signin");
    else if (location.pathname === "/signin" && user.token) navigate("/");
  }, [user, children, navigate, location.pathname]);
  return (
    <>
      {((user.token && location.pathname !== "/signin") ||
        (!user.token && location.pathname === "/signin")) &&
        children}
    </>
  );
}

export default AuthGuard;
