import React from 'react';
import AddAllQuestionTable from './add-all-table';
import AddAllQuestionTop from './add-all-top';

const AddAllQuestionContent = (props) => {
  const { ArrayData,perpage ,pagenumber,setLoading ,PageSize,setPageSize} = props;
  return (
    <div className='moderators-content'>
      <AddAllQuestionTop />
      <AddAllQuestionTable ArrayData={ArrayData} perpage={perpage} pagenumber={pagenumber} setLoading={setLoading} PageSize={PageSize} setPageSize={setPageSize}/>
    </div>
  )
}

export default AddAllQuestionContent;


