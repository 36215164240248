import React from "react"
import SinginNavbar from "./component/singin-navbar";
import LoginContent from "./component/singin-content";



const SingIn = () => {
  return (
    <section className="singin">
      <SinginNavbar/>
      <LoginContent/>
    </section>
  )
}

export default SingIn;