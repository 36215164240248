import React from 'react'


const MaterialExamTop = (props) => {
  const {Arraydata} = props;
  return (
    <div className='materials-exam-content__top'>
        <div className="materials-exam-content__top__left">
            <h6>
            الطالب /
              {Arraydata.studentName}
              </h6>
            <h6>رقم الهوية / {Arraydata.nationalId}</h6>
        </div>

        <div className="materials-exam-content__top__right">
            <h6>
                درجة الأختبار - {Arraydata.studentDegree} / 100
            </h6>
            <h6>
                تاريخ الأختبار - {Arraydata.testDate}
              </h6>
        </div>
    </div>
  )
}

export default MaterialExamTop