import React, { useState } from "react";
import NavbarLeft from "../../components/navbar/navbar_left";
import NavbarTop from "../../components/navbar/navbar_top";
import SurveysChart from "./survey-chart";
import HomeNavList from "../../components/home-sections/home-nav-list";
import ClassRoomChart from "./classroom-chart";
import StudentChart from "./student-chart";
import ExamsChart from "./exams-chart";

function HomeSections() {
  const [filter, setFilter] = useState("classroom");
  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={""} />
        <div className="main-body__content">
          <div className="home-content">
            <HomeNavList filter={filter} setFilter={setFilter} />
            {filter === "classroom" && (
              <div className="d-flex w-100">
                <ClassRoomChart />
                <ExamsChart />
                <StudentChart />
              </div>
            )}
            {filter === "survey" && <SurveysChart />}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeSections;
