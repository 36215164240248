import React from 'react'

import NavbarLeft from '../../../components/navbar/navbar_left'
import NavbarTop from '../../../components/navbar/navbar_top'
import ExamsAddContent from '../component/add/exams-add-content'
import { useParams } from 'react-router'

const ExamsAdd = () => {
    const { id, classroomid } = useParams();

    const ObJectArrayData = [
        {
            id: 2,
            href: `/exams/add/id:${id}/classroomid:${classroomid}`,
            title: "الاختبارات",
        }
    ]
  return (
    <section className='main'>
        <NavbarLeft />
        <div className="main-body">
            <NavbarTop ArrayData={ObJectArrayData} />
            <div className="main-body__content">
                <ExamsAddContent/>
            </div>
        </div>

    </section>
  )
}

export default ExamsAdd;
