/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import IconSearch from "../../../components/shared/icon-search";
import { FilterReset } from "../../../components/shared/reset-filter";

const CoursesTop = (props) => {
  const { setLoading } = props;
  const { search, active } = useParams();
  const searchKeyword = search.slice(search.indexOf(":") + 1, search.length);
  const activeCourse = active.slice(active.indexOf(":") + 1, active.length);
  let navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(
    searchKeyword ? searchKeyword : ""
  );
  const SearchClick = (e) => {
    e.preventDefault();
    navigate(`/courses/page:${1}/search:${search}`);
  };
  const resetFilters = (e) => {
    e.preventDefault();
    navigate(`/courses/page:${1}/search:/active:`);
    setLoading(false);
  };

  return (
    <div className="materials-content__top">
      <div className="materials-content__top__title">
        <h5>الدورات</h5>
      </div>

      <div className="materials-content__top__filter">
        <div className="input-group-search">
          <IconSearch SearchClick={SearchClick} />
          <input
            type="text"
            placeholder="بحث"
            defaultValue={searchValue}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                navigate(
                  `/courses/page:${1}/search:${
                    e.target.value
                  }/active:${activeCourse}`
                );
                setLoading(false);
              }
              setSearchValue(e.target.value);
            }}
          />
        </div>
        <MaterialsDropdown setLoading={setLoading} />
        {(searchKeyword || activeCourse) && (
          <FilterReset resetFilters={resetFilters} />
        )}
      </div>
    </div>
  );
};

export default CoursesTop;

const MaterialsDropdown = ({ setLoading }) => {
  const { search, active } = useParams();
  const searchKeyword = search.slice(search.indexOf(":") + 1, search.length);
  const activeCourse = active.slice(active.indexOf(":") + 1, active.length);

  const [activeCheckBox, setActiveCheckBox] = useState(
    activeCourse == "true" ? true : activeCourse == "false" ? false : null
  );

  let navigate = useNavigate();
  const data = [
    {
      id: 1,
      Name: "نشط",
      Value: true,
    },
    {
      id: 2,
      Name: "غير نشط",
      Value: false,
    },
  ];
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    const { value } = event.currentTarget;
    setValue(value);
    setActiveCheckBox(value);
  };

  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M14.6668 2.5H1.3335L6.66683 8.80667V13.1667L9.3335 14.5V8.80667L14.6668 2.5Z"
              stroke="#53545C"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span className="text">تصفية</span>
      </button>
      <ul className="dropdown-menu">
        <p>
          <span>تصفية حسب الدورة </span>
        </p>
        {data.map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="radio"
                id="flexCheckDefault"
                name={"sortclassroom"}
                value={item.Value}
                onChange={handleChange}
                defaultChecked={item.Value == activeCheckBox ? true : null}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>{item.Name}</span>
              </label>
            </li>
          );
        })}
        <button
          type="button"
          className="btn-sendapi"
          onClick={(e) => {
            e.preventDefault();
            navigate(
              `/courses/page:${1}/search:${searchKeyword}/active:${value}`
            );
            setLoading(false);
          }}
        >
          <span>تصفية</span>
        </button>
      </ul>
    </div>
  );
};
