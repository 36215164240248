import React from "react";
import ChartTop from "./charts-top";
import {
  ChartSurveyQuestions,
  ChartQuestionnaireStatistics,
} from "./chart-doughnut";
const ChartsContent = (props) => {
  const {
    Arraydata,
    surveyTypeId,
    setSurveyTypeId,
    classRoomCode,
    setClassRoomCode,
    classRoomNumber,
    setClassRoomNumber,
    classRoomName,
    setClassRoomName,
  } = props;

  return (
    <>
      <ChartTop
        surveyTypeId={surveyTypeId}
        setSurveyTypeId={setSurveyTypeId}
        classRoomCode={classRoomCode}
        setClassRoomCode={setClassRoomCode}
        classRoomNumber={classRoomNumber}
        setClassRoomNumber={setClassRoomNumber}
        classRoomName={classRoomName}
        setClassRoomName={setClassRoomName}
      />
      <div className="home-content__charts-row">
        <ChartSurveyQuestions Arraydata={Arraydata.choice} />
        <ChartQuestionnaireStatistics Arraydata={Arraydata.trueFalse} />
      </div>
    </>
  );
};

export default ChartsContent;

/*

    <div className='home-content__charts-row'>
      <ChartCourses />
      <ChartTheExams />
      <ChartTheStudents />
    </div>

    <div className='home-content__charts-row'>
      <ChartQuestionnaires />
      <ChartSystemUsers />
    </div>

*/
