/* eslint-disable eqeqeq */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Api } from "../../../../api";
import { Headers } from "../../../../api/actions";
import SurveyViewTopDropdownClassRoomName from "./survey-view-top-dropdown-classroom";
//import SurveyViewTopDropdownSubject from "./survey-view-top-dropdown-subject";
import SurveyViewTopDatePicker from "./survey-view-top-dateRange";
import IconSearch from "../../../../components/shared/icon-search";
import axiosInstance from "../../../../api/axios_instance";

const SurveyViewTop = (props) => {
  const { setStartDay, setEndDay, setIsLoading, classRoomID } = props;
  const { id } = useParams();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    GetData(id, setName, setLoading);
  }, [id]);
  const [search, setSearch] = useState("");
  const SearchClick = (e) => {
    e.preventDefault();
    navigate(
      `/survey/survey-view/${id}/page:1/search:${search}/sortclassroom:/subjectid:`
    );
  };

  if (!loading) {
    return (
      <div className="survey-view__top">
        <div className="survey-view__top__title">
          <h5>استبيان</h5>
        </div>

        <div className="survey-view__top__filter">
          <div className="survey-view__top__search">
            <IconSearch SearchClick={SearchClick} />
            <input
              type="text"
              placeholder="بحث"
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  navigate(
                    `/survey/survey-view/${id}/page:1/search:${e.target.value}/sortclassroom:/subjectid:`
                  );
                  setIsLoading(false);
                }
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="survey-view__top">
      <div className="survey-view__top__title">
        <h5>{name}</h5>
      </div>

      <div className="survey-view__top__filter">
        <div className="survey-view__top__search">
          <IconSearch SearchClick={SearchClick} />
          <input
            type="text"
            placeholder="بحث"
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                navigate(
                  `/survey/survey-view/${id}/page:1/search:${e.target.value}/sortclassroom:`
                );
                setIsLoading(false);
              }
              setSearch(e.target.value);
            }}
          />
        </div>
        <SurveyViewTopDatePicker
          setStartDay={setStartDay}
          setEndDay={setEndDay}
          setIsLoading={setIsLoading}
        />
        <SurveyViewTopDropdownClassRoomName
          setIsLoading={setIsLoading}
          classRoomID={classRoomID}
        />
      </div>
    </div>
  );
};

export default SurveyViewTop;

const GetData = async (Id, setName, setLoading) => {
  const options = {
    method: "GET",
    url: `${Api}Survey/GetSurveyTypes`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      setLoading(true);
      const Array = response.data.responseObject;
      for (let i = 0; i < Array.length; i++) {
        if (Array[i].id == Id) {
          setName(Array[i].name);
        }
      }
    })
    .catch(function (error) {
      setLoading(true);
    });
};
