import React from "react";
import ModeratorsTop from "./moderators-top";
import ModeratorsTable from "./moderators-table";

const ModeratorsContent = (props) => {
  const {
    ArrayData,
    perpage,
    pagenumber,
    setLoading,
    PageSize,
    setPageSize,
    setSortRole,
    setLoadingRole,
    loadingRole,
    sortRole,
  } = props;
  return (
    <div className="moderators-content">
      <ModeratorsTop
        setLoading={setLoading}
        setSortRole={setSortRole}
        setLoadingRole={setLoadingRole}
        loadingRole={loadingRole}
        sortRole={sortRole}
      />
      <ModeratorsTable
        ArrayData={ArrayData}
        perpage={perpage}
        pagenumber={pagenumber}
        setLoading={setLoading}
        PageSize={PageSize}
        setPageSize={setPageSize}
        loadingRole={loadingRole}
      />
    </div>
  );
};

export default ModeratorsContent;
