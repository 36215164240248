import React, { useEffect, useState } from "react";
import { Api } from "../../api";
import axios from "axios";
import { Headers } from "../../api/actions";
import Loading from "../../components/loading/loading";
import { useParams } from "react-router";

import NavbarLeft from "../../components/navbar/navbar_left";
import NavbarTop from "../../components/navbar/navbar_top";
import StudentsContent from "./component/students-content";
import axiosInstance from "../../api/axios_instance";

const Students = () => {
  const { page, search, sortclassroom, sortclasscode, sortclassroomnamber } =
    useParams();
  const pageCount = +page.slice(page.indexOf(":") + 1, page.length);
  const searchKeyword = search.slice(search.indexOf(":") + 1, search.length);
  const classRoomName = sortclassroom.slice(
    sortclassroom.indexOf(":") + 1,
    sortclassroom.length
  );
  const classcode = sortclasscode.slice(
    sortclasscode.indexOf(":") + 1,
    sortclasscode.length
  );
  const classRoomNumber = sortclassroomnamber.slice(
    sortclassroomnamber.indexOf(":") + 1,
    sortclassroomnamber.length
  );
  const [perpage, setPerpage] = useState(0);
  const [data, setData] = useState([]);
  const [PageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const ObJectArrayData = [
    {
      id: 2,
      href: `/students/page:${1}/search:${""}`,
      title: "قائمة الطلاب",
    },
  ];

  useEffect(() => {
    GetData(
      setData,
      setLoading,
      pageCount,
      searchKeyword,
      classRoomName,
      classRoomNumber,
      setPerpage,
      PageSize
    );
  }, [pageCount, searchKeyword, PageSize, classRoomName, classRoomNumber]);

  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <StudentsContent
            ArrayData={data}
            perpage={perpage}
            pagenumber={pageCount}
            setLoading={setLoading}
            PageSize={PageSize}
            setPageSize={setPageSize}
            classRoomName={classRoomName}
            classRoomNumber={classRoomNumber}
            classcode={classcode}
          />
        </div>
      </div>
    </section>
  );
};

export default Students;

const GetData = async (
  setData,
  setLoading,
  pageCount,
  searchKeyword,
  classRoomName,
  classRoomNumber,
  setPerpage,
  PageSize
) => {
  var data = new FormData();
  setLoading(false);
  data.append("pageNumber", pageCount);
  data.append("searchKeyword", searchKeyword);
  data.append("classRoomName", classRoomName);
  data.append("classRoomNumber", classRoomNumber);
  data.append("pageSize", PageSize);
  const options = {
    method: "post",
    url: `${Api}Student/SearchForStudents`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      // console.log(response.data.responseObject);
      setData(response.data.responseObject.items);
      setLoading(true);

      setPerpage(
        Math.ceil(
          response.data.responseObject.totalItemsCount /
            response.data.responseObject.pageSize
        )
      );
    })
    .catch(function (error) {
      setLoading(true);
    });
};
