import React, { useEffect, useState } from "react";
import NavbarLeft from "../../components/navbar/navbar_left";
import NavbarTop from "../../components/navbar/navbar_top";
import { NotificationsContent } from "./component/notifications-content";
import axios from "axios";
import { Api } from "../../api/index";
import { Headers } from "../../api/actions";
import Loading from "../../components/loading/loading";
import axiosInstance from "../../api/axios_instance";

const Notifications = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const ObJectArrayData = [
    {
      id: 2,
      href: "/notifications",
      title: "الإشعارات",
    },
  ];

  useEffect(() => {
    GetData(setData, setIsLoading);
  }, []);

  if (!isLoading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <NotificationsContent ArrayData={data} />
        </div>
      </div>
    </section>
  );
};

export default Notifications;

const GetData = async (setData, setIsLoading) => {
  const options = {
    method: "get",
    url: `${Api}Test/GetExitStudents`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setIsLoading(true);
    })
    .catch(function (error) {
      setIsLoading(true);
    });
};
