/* eslint-disable eqeqeq */
import React from "react";

const MaterialExamRows = (props) => {
  const { Arraydata } = props;
  return (
    <div className="materials-exam-content__rows">
      <CardQuestions Arraydata={Arraydata} />
    </div>
  );
};

export default MaterialExamRows;

const CardQuestions = (props) => {
  const { Arraydata } = props;

  return (
    <>
      <MultipleChoice Questions={Arraydata.choiceQuestions} />
      <TrueAndFalse Questions={Arraydata.trueFalseQuestions} />
      <Delivery Questions={Arraydata} />
    </>
  );
};

const TrueAndFalse = (props) => {
  const { Questions } = props;
  return (
    <>
      {Questions.map((item, index) => {
        return (
          <div className="materials-exam-content__rows__one" key={index}>
            <h6>
              <span>{item.questionName}</span>
            </h6>

            <div className="form-check-rows">
              {item.answers.map((elment, index) => {
                return (
                  <div
                    className={
                      elment.answerId == item.studentAnswerId
                        ? "form-check success"
                        : "form-check"
                    }
                    key={index}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="one"
                      id={`card-four-${elment.answerId}`}
                      disabled
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`card-four-${elment.answerId}`}
                    >
                      {elment.answerName}
                    </label>
                  </div>
                );
              })}
            </div>
            {item.isCorrect === false ? (
              <span className="error-text">هذه الأجابة خاطئة</span>
            ) : (
              <span className="success-text">هذه الأجابة صحيحة</span>
            )}
          </div>
        );
      })}
    </>
  );
};

const Delivery = (props) => {
  const { Questions } = props;
  console.log(Questions);
  return (
    <>
      {Questions.matchingQuestionsByGroup.map((elment, index) => (
        <div className="materials-exam-content__rows__one" key={elment.groupId}>
          <h6>
            <span>{index + 1} - سؤال توصيل</span>
          </h6>

          <ul className="materials-exam-content__rows__one__rows">
            {elment.matchingQuestions.map((item, index) => {
              return (
                <li key={index}>
                  <span className="item">
                    <span className="number">
                      <span>{index + 1}</span>
                    </span>
                    <input
                      type="text"
                      name=""
                      id={item.questionId}
                      defaultValue={item.questionName}
                      disabled
                    />
                  </span>

                  <span
                    className={
                      item.isCorrect === true ? "item success" : "item error"
                    }
                  >
                    <span className="number">{index + 1}</span>
                    <input
                      type="text"
                      name=""
                      id={item.studentAnswerId}
                      defaultValue={item.studentAnswerName}
                      disabled
                    />
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      ))}
    </>
  );
};

const MultipleChoice = (props) => {
  const { Questions } = props;
  return (
    <>
      {Questions.map((item, index) => {
        return (
          <div className="materials-exam-content__rows__one" key={index}>
            <h6>
              <span>{item.questionName}</span>
            </h6>
            <div className="form-check-rows">
              {item.answers.map((elment, index) => {
                return (
                  <div
                    className={
                      elment.answerId == item.studentAnswerId
                        ? "form-check success"
                        : "form-check"
                    }
                    key={index}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="one"
                      id={`card-four-${elment.answerId}`}
                      disabled
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`card-four-${elment.answerId}`}
                    >
                      {elment.answerName}
                    </label>
                  </div>
                );
              })}
            </div>
            {item.isCorrect === false ? (
              <span className="error-text">هذه الأجابة خاطئة</span>
            ) : (
              <span className="success-text">هذه الأجابة صحيحة</span>
            )}
          </div>
        );
      })}
    </>
  );
};
