import React, { useEffect, useState } from "react";
import AddFormCardOne from "./add-form-card-one";
import AddFormCardTwo from "./add-form-card-two";
//import AddFormCardFour from "./add-form-card-four";
import AddFormCardThree from "./add-form-card-three";
import { Headers } from "../../../../api/actions";
import { Api } from "../../../../api";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import AddFormCardFour from "../exam-repetiton/repetiton-form-card-four";
import axiosInstance from "../../../../api/axios_instance";

const ExamsAddForm = () => {
  const [classRoom, setClassRoom] = useState("");
  const [subject, setSubject] = useState("");
  const [numberClassRoom, setNumberClassRoom] = useState("");
  const navigate = useNavigate();

  const [day, setDay] = useState(null);
  const [testDuration, setTestDuration] = useState("");

  const [startTime, setStartTime] = useState(null);

  const [numberDelivery, setNumberDelivery] = useState(0);
  const [matchingCount, setMatchingCount] = useState(0);
  const [numberMultipleChoice, setMultipleChoice] = useState(0);
  const [multiChoiceCount, setMultiChoiceCount] = useState(0);
  const [numberTrueAndFalse, setNumberTrueAndFalse] = useState(0);
  const [trueAndFalseCount, setTrueAndFalseCount] = useState(0);

  const [degreesDelivery, setDegreesDelivery] = useState(0);
  const [degreesMultipleChoice, setDegreesMultipleChoice] = useState(0);
  const [degreesTrueAndFalse, setDegreesTrueAndFalse] = useState(0);

  const [isRandomQuestions, setIsRandomQuestions] = useState(false);
  const [showPrfewQuestions, setShowPrfewQuestions] = useState(false);
  // عرض الاسئله للطالب بنفس الترتيب الذي تم ادخالها
  const [isShowRandomToStudent, setIsShowRandomToStudent] = useState(true);

  const [arrayQuestions, setArrayQuestions] = useState([]);
  useEffect(() => {
    setShowPrfewQuestions(false);
  }, [
    numberDelivery,
    matchingCount,
    numberMultipleChoice,
    multiChoiceCount,
    numberTrueAndFalse,
    trueAndFalseCount,
    degreesDelivery,
    degreesMultipleChoice,
    degreesTrueAndFalse,
  ]);

  const [error, setError] = useState({
    classRoom: "",
    subject: "",
    numberClassRoom: "",
    day: "",
    testDuration: "",
    startTime: "",
    numberOfDegrees: "",
    numberQuen: "",
    arrayQuestions: "",
  });

  const CheckEmptyValue = () => {
    if (classRoom === "") {
      setError({ ...error, classRoom: "يجب اختيار الدورة" });
    } else if (numberClassRoom === "") {
      setError({ ...error, numberClassRoom: "يجب اختيار رقم الدورة" });
    } else if (subject === "") {
      setError({ ...error, subject: "يجب اختيار المادة" });
    } else if (day === null) {
      setError({ ...error, day: "يجب اختيار اليوم" });
    } else if (testDuration === "") {
      setError({ ...error, testDuration: "يجب ادخال مدة الامتحان" });
    } else if (startTime === null) {
      setError({ ...error, startTime: "يجب ادخال وقت بداية الامتحان" });
    } else if (
      numberDelivery === 0 &&
      numberMultipleChoice === 0 &&
      numberTrueAndFalse === 0 &&
      isRandomQuestions === true
    ) {
      setError({ ...error, numberOfDegrees: "يجب ادخال عدد الاسئلة" });
    } else if (
      degreesDelivery === 0 &&
      degreesMultipleChoice === 0 &&
      degreesTrueAndFalse === 0
    ) {
      setError({ ...error, numberQuen: " يجب ادخال درجات الاسئلة لكل النوع" });
    } else if (arrayQuestions.length === 0 && isRandomQuestions === false) {
      setError({ ...error, arrayQuestions: "يجب اختيار الاسئلة" });
    } else {
      setError({
        classRoom: "",
        subject: "",
        day: "",
        testDuration: "",
        startTime: "",
        numberQuen: "",
        arrayQuestions: "",
      });
      if (isRandomQuestions === true && showPrfewQuestions === false) {
        SenDataPreviewTest(
          subject,
          classRoom,
          numberClassRoom,
          day,
          testDuration,
          startTime,
          numberDelivery,
          numberMultipleChoice,
          numberTrueAndFalse,
          degreesDelivery,
          degreesMultipleChoice,
          degreesTrueAndFalse,
          isRandomQuestions,
          isShowRandomToStudent,
          arrayQuestions,
          setShowPrfewQuestions,
          setArrayQuestions,
          navigate
        );
      } else {
        SenDataAddEditTest(
          subject,
          classRoom,
          numberClassRoom,
          day,
          testDuration,
          startTime,
          numberDelivery,
          numberMultipleChoice,
          numberTrueAndFalse,
          degreesDelivery,
          degreesMultipleChoice,
          degreesTrueAndFalse,
          isRandomQuestions,
          isShowRandomToStudent,
          arrayQuestions,
          navigate
        );
      }
    }
  };

  const OnSubmit = (e) => {
    e.preventDefault();
    CheckEmptyValue();
  };
  const OnCansel = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <div className="exams-content-add__form">
      <AddFormCardOne
        classRoom={classRoom}
        setClassRoom={setClassRoom}
        numberClassRoom={numberClassRoom}
        setNumberClassRoom={setNumberClassRoom}
        subject={subject}
        setSubject={setSubject}
        Day={day}
        setDay={setDay}
        testDuration={testDuration}
        setTestDuration={setTestDuration}
        startTime={startTime}
        setStartTime={setStartTime}
        Error={error}
        setError={setError}
      />
      <br />
      <AddFormCardTwo
        numberDelivery={numberDelivery}
        setNumberDelivery={setNumberDelivery}
        numberMultipleChoice={numberMultipleChoice}
        setMultipleChoice={setMultipleChoice}
        numberTrueAndFalse={numberTrueAndFalse}
        setNumberTrueAndFalse={setNumberTrueAndFalse}
        degreesDelivery={degreesDelivery}
        setDegreesDelivery={setDegreesDelivery}
        degreesMultipleChoice={degreesMultipleChoice}
        setDegreesMultipleChoice={setDegreesMultipleChoice}
        degreesTrueAndFalse={degreesTrueAndFalse}
        setDegreesTrueAndFalse={setDegreesTrueAndFalse}
        isRandomQuestions={isRandomQuestions}
        setIsRandomQuestions={setIsRandomQuestions}
        setArrayQuestions={setArrayQuestions}
        arrayQuestions={arrayQuestions}
        Error={error}
        setError={setError}
        matchingCount={matchingCount}
        multiChoiceCount={multiChoiceCount}
        trueAndFalseCount={trueAndFalseCount}
      />
      {subject === "" ? null : (
        <AddFormCardThree
          isRandomQuestions={isRandomQuestions}
          arrayQuestions={arrayQuestions}
          setArrayQuestions={setArrayQuestions}
          subject={subject}
          Error={error}
          setError={setError}
          setMatchingCount={setMatchingCount}
          setMultiChoiceCount={setMultiChoiceCount}
          setTrueAndFalseCount={setTrueAndFalseCount}
        />
      )}
      <br />
      <AddFormCardFour
        isShowRandomToStudent={isShowRandomToStudent}
        setIsShowRandomToStudent={setIsShowRandomToStudent}
      />
      <div className="exams-content-add__form__buttons">
        {isRandomQuestions === true && showPrfewQuestions === false ? (
          <button className="btn btn-primary" onClick={OnSubmit}>
            معاينة الاسئلة
          </button>
        ) : isRandomQuestions === true && showPrfewQuestions === true ? (
          <button className="btn btn-primary" onClick={OnSubmit}>
            تمت المعاينه حفظ الامتحان
          </button>
        ) : (
          <button className="btn btn-primary" onClick={OnSubmit}>
            حفظ
          </button>
        )}
        <button className="btn btn-secondary" onClick={OnCansel}>
          إلغاء
        </button>
      </div>
    </div>
  );
};

export default ExamsAddForm;

const SenDataPreviewTest = async (
  Subjectid,
  classroomId,
  ClassRoomNumber,
  day,
  testDuration,
  startTime,
  numberDelivery,
  numberMultipleChoice,
  numberTrueAndFalse,
  degreesDelivery,
  degreesMultipleChoice,
  degreesTrueAndFalse,
  isRandomQuestions,
  isShowRandomToStudent,
  arrayQuestions,
  setShowPrfewQuestions,
  setArrayQuestions,
  navigate
) => {
  const TimeStringHours =
      startTime.getHours() < 10
        ? `0${startTime.getHours()}`
        : startTime.getHours(),
    TimeStringMinutes =
      startTime.getMinutes() < 10
        ? `0${startTime.getMinutes()}`
        : startTime.getMinutes();
  const Month =
    day.getMonth() + 1 < 10 ? `0${day.getMonth() + 1}` : day.getMonth() + 1;
  const Day = day.getDate() < 10 ? `0${day.getDate()}` : day.getDate();

  const DayIso = `${day.getFullYear()}-${Month}-${Day}`;
  const TimeMinutes = `${TimeStringHours}:${TimeStringMinutes}`;

  const options = {
    method: "post",
    // url: `${Api}Test/AddEditTest`,
    url: `${Api}Test/PreviewTest`,
    headers: Headers,
    data: {
      subjectId: Subjectid,
      classRoomId: classroomId,
      ClassRoomNumber,
      testDate: DayIso,
      testStartTime: TimeMinutes,
      testDurationInMinutes: testDuration,
      isShowResult: true,
      /* عد الأسئله */
      multiChoiceQuestionDegree: degreesMultipleChoice,
      trueFalseQuestionDegree: degreesTrueAndFalse,
      matchingQuestionDegree: degreesDelivery,
      /* عدد الدراجات */
      numberOfMultiChoiceQuestionDegree: numberMultipleChoice,
      numberOfTrueFalseQuestionDegree: numberTrueAndFalse,
      numberOfMatchingQuestionDegree: numberDelivery,
      isRandomQuestions: isRandomQuestions,
      questions: arrayQuestions,
      isShowRandomToStudent: isShowRandomToStudent,
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description === "Error") {
        swal(`${response.data.responseStatus.errors[0].errorMessage}`, {
          icon: "error",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        });
      } else {
        if (isRandomQuestions === true) {
          setArrayQuestions(response.data.responseObject.questions);
          setShowPrfewQuestions(true);
        }
        /*
        swal("تم اضافة الامتحان بنجاح", {
          icon: "success",
          confirmButtonColor: "#d33",
          button: {
          text: "إغلاق",
          closeModal: true,
        },
        }).then((result) => {
          if (result === true) {
            navigate(-1);
          }
        });
        */
      }
    })

    .catch(function (error) {});
};

const SenDataAddEditTest = async (
  Subjectid,
  classroomId,
  ClassRoomNumber,
  day,
  testDuration,
  startTime,
  numberDelivery,
  numberMultipleChoice,
  numberTrueAndFalse,
  degreesDelivery,
  degreesMultipleChoice,
  degreesTrueAndFalse,
  isRandomQuestions,
  isShowRandomToStudent,
  arrayQuestions,
  navigate
) => {
  const TimeStringHours =
      startTime.getHours() < 10
        ? `0${startTime.getHours()}`
        : startTime.getHours(),
    TimeStringMinutes =
      startTime.getMinutes() < 10
        ? `0${startTime.getMinutes()}`
        : startTime.getMinutes();
  const Month =
    day.getMonth() + 1 < 10 ? `0${day.getMonth() + 1}` : day.getMonth() + 1;
  const Day = day.getDate() < 10 ? `0${day.getDate()}` : day.getDate();

  const DayIso = `${day.getFullYear()}-${Month}-${Day}`;
  const TimeMinutes = `${TimeStringHours}:${TimeStringMinutes}`;

  const options = {
    method: "post",
    url: `${Api}Test/AddEditTest`,
    headers: Headers,
    data: {
      subjectId: Subjectid,
      classRoomId: classroomId,
      ClassRoomNumber,
      testDate: DayIso,
      testStartTime: TimeMinutes,
      testDurationInMinutes: testDuration,
      isShowResult: true,
      /* عد الأسئله */
      multiChoiceQuestionDegree: degreesMultipleChoice,
      trueFalseQuestionDegree: degreesTrueAndFalse,
      matchingQuestionDegree: degreesDelivery,
      /* عدد الدراجات */
      numberOfMultiChoiceQuestionDegree: numberMultipleChoice,
      numberOfTrueFalseQuestionDegree: numberTrueAndFalse,
      numberOfMatchingQuestionDegree: numberDelivery,
      isRandomQuestions: isRandomQuestions,
      questions: arrayQuestions,
      isShowRandomToStudent: isShowRandomToStudent,
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description === "Error") {
        swal(`${response.data.responseStatus.errors[0].errorMessage}`, {
          icon: "error",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        });
      } else {
        swal("تم اضافة الامتحان بنجاح", {
          icon: "success",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            navigate(-1);
          }
        });
      }
    })

    .catch(function (error) {});
};
