import React from 'react'
import MaterialsSubjectTop from './subject-top';
import MaterialsSubjectTable from './subject-table';

const MaterialsSubjectContent = (props) => {
  const { ArrayData,perpage ,pagenumber,setLoading ,PageSize,setPageSize}= props;
  return (
    <div className='materials-content'>
      
        <MaterialsSubjectTop ArrayData={ArrayData} setLoading={setLoading}/>
        <MaterialsSubjectTable ArrayData={ArrayData} perpage={perpage} pagenumber={pagenumber} setLoading={setLoading} 
        PageSize={PageSize} setPageSize={setPageSize}/>
    </div>
  )
}

export default MaterialsSubjectContent;