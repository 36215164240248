export function downloadBase64File(base64Data, fileName, contentType) {
  const binaryData = atob(base64Data);
  const binaryArray = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    binaryArray[i] = binaryData.charCodeAt(i);
  }
  const blob = new Blob([binaryArray], { type: contentType });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);

  link.download = fileName;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
}
