import React from "react";
import StudentsTop from "./students-top";
import StudentsTable from "./students-table";

const StudentsContent = (props) => {
  const {
    ArrayData,
    perpage,
    pagenumber,
    setLoading,
    PageSize,
    setPageSize,
    classRoomName,
    classRoomNumber,
    classcode
  } = props;
  return (
    <div className="students-content">
      <StudentsTop
        setLoading={setLoading}
        classRoomName={classRoomName}
        classRoomNumber={classRoomNumber}
      />
      <StudentsTable
        ArrayData={ArrayData}
        perpage={perpage}
        pagenumber={pagenumber}
        setLoading={setLoading}
        PageSize={PageSize}
        setPageSize={setPageSize}
        classRoomName={classRoomName}
        classRoomNumber={classRoomNumber}
        classcode={classcode}
      />
    </div>
  );
};

export default StudentsContent;
