import React from 'react';
import ExamsRepetitonForm from './exams-repetiton-form';

const ExamsRepetitonContent = () => {
  return (
    <div className='exams-content-add'>
      <ExamsRepetitonTop/>
      <ExamsRepetitonForm/>
    </div>
  )
}

export default ExamsRepetitonContent;


const ExamsRepetitonTop=()=>{
    return(
        <div className='exams-content-add__title'>
            <div className='d-flex justify-content-between align-items-center w-100'>
                <h5 className='size-h5'>
                   أعاده الاختبار
                </h5>
            </div >
        </div>
    )
}




