import React from 'react';
import ListSurveyStudentsTop from './list-survey-view-top';
import ListSurveyStudentsTable from './list-survey-view-table';
import ListSurveyStudentsChart from './list-survey-students-chart';

const ListSurveyStudentsContent = (props) => {
  const { ArrayData,perpage ,pagenumber,setLoading ,PageSize,setPageSize} = props;
  return (
    <div className='survey-view'>
        <ListSurveyStudentsTop ArrayData={ArrayData} setLoading={setLoading}/>
        <ListSurveyStudentsChart />
        <ListSurveyStudentsTable ArrayData={ArrayData} perpage={perpage} pagenumber={pagenumber} setLoading={setLoading}
           PageSize={PageSize} setPageSize={setPageSize}/>
    </div>
  )
}

export default ListSurveyStudentsContent;


