/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loading from "../../../../components/loading/loading";
import axios from "axios";
import { Headers } from "../../../../api/actions";
import { Api } from "../../../../api";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";
import axiosInstance from "../../../../api/axios_instance";

function AddFormCardThree(props) {
  const {
    subject,
    isRandomQuestions,
    arrayQuestions,
    setArrayQuestions,
    Error,
    setError,
    setMatchingCount,
    setMultiChoiceCount,
    setTrueAndFalseCount,
  } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [perpage, setPerpage] = useState(0);
  const [pagenumber, setPageNumber] = useState(1);

  useEffect(() => {
    GetData(
      subject,
      setData,
      setLoading,
      pagenumber,
      setPerpage,
      setMatchingCount,
      setMultiChoiceCount,
      setTrueAndFalseCount,
      setArrayQuestions
    );
  }, [subject, pagenumber]);

  const handlePageClick = (data) => {
    let number = 1 + data.selected;
    setPageNumber(number);
  };
  useEffect(() => {}, [data, arrayQuestions]);
  if (!loading) {
    return (
      <div className="exams-content-add__form__card">
        <Loading />
      </div>
    );
  }
  return (
    <div className="exams-content-add__form__table">
      <TableContainer
        // All Data for Table
        data={data}
        Error={Error}
        setError={setError}
        arrayQuestions={arrayQuestions}
        setArrayQuestions={setArrayQuestions}
        isRandomQuestions={isRandomQuestions}
        handlePageClick={handlePageClick}
        perpage={perpage}
        pagenumber={pagenumber}
        subjectId={subject}
      />
    </div>
  );
}

export default AddFormCardThree;

const TableContainer = (props) => {
  const {
    data,
    Error,
    setError,
    arrayQuestions,
    setArrayQuestions,
    isRandomQuestions,
    handlePageClick,
    perpage,
    pagenumber,
    subjectId,
  } = props;

  return (
    <div className="table-container">
      <AddAllQuestionTableHead />
      <ul
        className="table-container__body"
        style={{
          minHeight: "max-content",
        }}
      >
        {data.map((item) => {
          return (
            <li key={item.id}>
              <AddAllQuestionTableBody
                Item={item}
                Error={Error}
                setError={setError}
                arrayQuestions={arrayQuestions}
                setArrayQuestions={setArrayQuestions}
                isRandomQuestions={isRandomQuestions}
                subjectId={subjectId}
              />
            </li>
          );
        })}
      </ul>
      <br />
      <Paginate
        handlePageClick={handlePageClick}
        perpage={perpage}
        pagenumber={pagenumber}
      />
      {Error.arrayQuestions && (
        <span className="text-danger">{Error.arrayQuestions}</span>
      )}
    </div>
  );
};

const AddAllQuestionTableHead = () => {
  return (
    <ul className="table-container__head">
      <li>
        <span className="justify-content-start">نوع السؤال</span>
        <span>نص السؤال </span>
        <span className="justify-content-end">الإجراءات</span>
      </li>
    </ul>
  );
};

const AddAllQuestionTableBody = (props) => {
  const {
    isRandomQuestions,
    Item,
    arrayQuestions,
    setArrayQuestions,
    Error,
    setError,
    subjectId,
  } = props;

  const Check = () => {
    if (arrayQuestions.find((x) => x.questionId === Item.id)) {
      setArrayQuestions(arrayQuestions.filter((x) => x.questionId !== Item.id));
    } else {
      setArrayQuestions([
        ...arrayQuestions,
        {
          questionTypeId: Item.questionTypeId,
          questionId: Item.id,
        },
      ]);
    }
    setError({ ...Error, arrayQuestions: "" });
  };
  return (
    <>
      <span className="justify-content-start">{Item.questionTypeName}</span>
      <span className="text-end">{Item.name}</span>
      <span className="justify-content-end">
        <>
          {" "}
          {Item.questionTypeId === 3 ? (
            <NavLink
              type="button"
              to={`/questionbanck/edite-question-matching/${Item.id}/subjectid:${subjectId}`}
              target="_blank"
              className="btn btn-show"
            >
              <img src="/assets/icons/eye-show.svg" alt="edit" />
            </NavLink>
          ) : (
            <NavLink
              type="button"
              to={`/questionbanck/edite-question/${Item.id}/subjectid:${subjectId}`}
              target="_blank"
              className="btn btn-show"
            >
              <img src="/assets/icons/eye-show.svg" alt="edit" />
            </NavLink>
          )}
        </>
        <button
          type="button"
          className={
            arrayQuestions.find((x) => x.questionId === Item.id)
              ? "btn btn-add btn-add-clicked"
              : "btn btn-add"
          }
          onClick={(e) => {
            e.preventDefault();
            Check();
          }}
          disabled={isRandomQuestions}
        >
          {arrayQuestions.find((x) => x.questionId === Item.id)
            ? "تمت الاضافه"
            : "أضافه الى الامتحان"}
        </button>
      </span>
    </>
  );
};

function Paginate(props) {
  const { handlePageClick, perpage, pagenumber } = props;

  return (
    <div className="paginate_control justify-content-end">
      <ReactPaginate
        previousLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9 18L15 12L9 6"
              stroke="#64748B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        nextLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 6L9 12L15 18"
              stroke="#94A3B8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        breakLabel={"..."}
        pageCount={perpage}
        postsPerPage={2}
        pageSize={1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        renderOnZeroPageCount={null}
        forcePage={pagenumber - 1}
      />
    </div>
  );
}

const GetData = async (
  Subjectid,
  setData,
  setLoading,
  pagenumber,
  setPerpage,
  setMatchingCount,
  setMultiChoiceCount,
  setTrueAndFalseCount,
  setArrayQuestions
) => {
  const options = {
    method: "post",
    url: `${Api}Question/SearchForQuestions`,
    headers: Headers,
    data: {
      subjectId: Subjectid,
      pageSize: 10,
      pageNumber: pagenumber,
      searchKeyword: "",
      answerTypeId: 0,
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.items);
      setMatchingCount(response.data.responseObject.matchingCount);
      setMultiChoiceCount(response.data.responseObject.multiChoiceCount);
      setTrueAndFalseCount(response.data.responseObject.trueFalseCount);
      setLoading(true);
      setArrayQuestions((prv) =>
        prv.filter((itm) =>
          response.data.responseObject.items.some(
            (q) => q.id === itm.questionId
          )
        )
      );
      setPerpage(
        Math.ceil(
          response.data.responseObject.totalItemsCount /
            response.data.responseObject.pageSize
        )
      );
    })
    .catch(function (error) {
      setLoading(true);
    });
};
