import React from "react";
import "./icon-loading.css"; // Ensure to add the appropriate CSS

const LoadingIcon = ({ size }) => {
  return (
    <div
      className="loading-icon"
      style={{ width: size ?? "20px", height: size ?? "20px" }}
    >
      <div
        className="spinner"
        style={{ width: size ?? "20px", height: size ?? "20px" }}
      ></div>
    </div>
  );
};

export default LoadingIcon;
