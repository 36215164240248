import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { Api } from "../../../api/index.js";
import { Headers } from "../../../api/actions.js";
import Loading from "../../../components/loading/loading";

import NavbarLeft from "../../../components/navbar/navbar_left";
import NavbarTop from "../../../components/navbar/navbar_top";
import AddAllQuestionContent from "../component/add-all-content/index.jsx";
import axiosInstance from "../../../api/axios_instance.jsx";

const AddAllQuestion = () => {
  const { id, page } = useParams();
  const pageCount = +page.slice(page.indexOf(":") + 1, page.length);
  const [perpage, setPerpage] = useState(0);
  const [PageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    GetData(id, setData, setLoading, pageCount, setPerpage, PageSize);
  }, [id, pageCount, PageSize]);

  const ObJectArrayData = [
    {
      id: 2,
      href: `/questionbanck/page:${1}/search:${""}/sortclassroom:${""}`,
      title: "بنك الاسئلة",
    },
  ];

  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <AddAllQuestionContent
            ArrayData={data}
            perpage={perpage}
            pagenumber={pageCount}
            setLoading={setLoading}
            PageSize={PageSize}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </section>
  );
};

export default AddAllQuestion;

const GetData = async (
  id,
  setData,
  setLoading,
  pageCount,
  setPerpage,
  PageSize
) => {
  var data = new FormData();
  data.append("subjectId", id);
  data.append("pageSize", PageSize);
  data.append("pageNumber", pageCount);
  data.append("searchKeyword", "");
  data.append("answerTypeId", 0);

  const options = {
    method: "post",
    url: `${Api}Question/SearchForQuestions`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.items);
      setLoading(true);
      setPerpage(
        Math.ceil(
          response.data.responseObject.totalItemsCount /
            response.data.responseObject.pageSize
        )
      );
    })
    .catch(function (error) {
      setLoading(true);
    });
};
