import React from 'react'
import QuestionCard from './question-card';

const ChoiceQuestionContent = (props) => {
  const { data } = props;
  return (
    <div className='add-question-content'>
        <EditeQuestionTop/>
        <QuestionCard data={data}/>
    </div>
  )
}
export default ChoiceQuestionContent; 

const EditeQuestionTop = () => {
    return (
        <div className='add-question__top d-flex justify-content-between align-items-center w-100'>
            <h5 className='size-h5'> عرض السؤال</h5>
        </div>
    )
}
