import React, { useEffect, useState } from "react";
import axios from "axios";
import { Api } from "../../../api/index.js";
import { Headers } from "../../../api/actions";
import Loading from "../../../components/loading/loading";

import NavbarLeft from "../../../components/navbar/navbar_left";
import NavbarTop from "../../../components/navbar/navbar_top";
import { useParams } from "react-router";
import ListStudentsContent from "../component/liststudents/list-students-content";
import axiosInstance from "../../../api/axios_instance.jsx";

const ListStudents = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const ObJectArrayData = [
    {
      id: 2,
      href: `/exams/page:${1}/search:${""}`,
      title: "الاختبارات",
    },
  ];

  useEffect(() => {
    GetData(id, setData, setLoading);
  }, [id]);

  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <ListStudentsContent setLoading={setLoading} ArrayData={data} />
        </div>
      </div>
    </section>
  );
};

export default ListStudents;

const GetData = async (id, setData, setLoading) => {
  var data = new FormData();
  data.append("testId", id);
  data.append("searchKeyword", "");

  const options = {
    method: "post",
    url: `${Api}Test/GetAllStudentsByTestId`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.items);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
