/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Api } from "../../../api/index.js";
import { Headers } from "../../../api/actions.js";
import Loading from "../../../components/loading/loading";

import NavbarLeft from "../../../components/navbar/navbar_left";
import NavbarTop from "../../../components/navbar/navbar_top";
import ShowSurveyContent from "../component/show-survey";
import { useParams } from "react-router";
import axiosInstance from "../../../api/axios_instance.jsx";

const ShowSurvey = () => {
  const { page, studentid, subjectid, surveytypeid } = useParams();
  const pageCount = +page.slice(page.indexOf(":") + 1, page.length);
  const subject = subjectid.slice(subjectid.indexOf(":") + 1, subjectid.length);
  const [perpage, setPerpage] = useState(0);
  const [PageSize, setPageSize] = useState(10);
  const surveyTypeId = surveytypeid.slice(
    surveytypeid.indexOf(":") + 1,
    surveytypeid.length
  );
  const studentId = studentid.slice(
    studentid.indexOf(":") + 1,
    studentid.length
  );

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const ObJectArrayData = [
    {
      id: 2,
      href: `/survey`,
      title: "الاستبيانات",
    },
  ];
  useEffect(() => {
    GetData(
      studentId,
      surveyTypeId,
      subject,
      setData,
      setLoading,
      pageCount,
      PageSize,
      setPerpage
    );
  }, [pageCount]);

  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <ShowSurveyContent
            ArrayData={data}
            perpage={perpage}
            pagenumber={pageCount}
            setLoading={setLoading}
            PageSize={PageSize}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </section>
  );
};

export default ShowSurvey;

const GetData = async (
  studentId,
  surveyTypeId,
  subject,
  setData,
  setLoading,
  pageCount,
  PageSize,
  setPerpage
) => {
  var data = new FormData();
  data.append("pageNumber", pageCount);
  data.append("pageSize", PageSize);
  data.append("surveyTypeId", surveyTypeId);
  data.append("studentId", studentId);
  data.append("subjectId", subject);
  const options = {
    method: "post",
    url: `${Api}Survey/GetSurveyAnswer`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);

      setPerpage(
        Math.ceil(
          response.data.responseObject.totalItemsCount /
            response.data.responseObject.pageSize
        )
      );
    })
    .catch(function (error) {
      setLoading(true);
    });
};
