import React, { useEffect, useState } from "react";
import { Api } from "../../../api";
import axios from "axios";
import { Headers } from "../../../api/actions";
import Loading from "../../../components/loading/loading";
import { useParams } from "react-router";

import NavbarLeft from "../../../components/navbar/navbar_left";
import NavbarTop from "../../../components/navbar/navbar_top";
import MaterialExamContent from "../component/material-exam/index";
import axiosInstance from "../../../api/axios_instance";

const MaterialExam = () => {
  const { testid, studentid } = useParams();
  const StudentId = studentid.slice(
    studentid.indexOf(":") + 1,
    studentid.length
  );
  const TestId = testid.slice(testid.indexOf(":") + 1, testid.length);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetData(setData, setLoading, StudentId, TestId);
  }, [StudentId, TestId]);

  const ObJectArrayData = [
    {
      id: 2,
      href: `/materials/page:${1}/search:${""}/sortclassroom:${""}`,
      title: "المواد التعليمية",
    },
  ];
  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <MaterialExamContent Arraydata={data} />
        </div>
      </div>
    </section>
  );
};

export default MaterialExam;

const GetData = async (setData, setLoading, StudentId, testId) => {
  var data = new FormData();
  data.append("studentId", StudentId);
  data.append("testId", testId);

  const options = {
    method: "post",
    url: `${Api}Test/GetStudentTestData`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
