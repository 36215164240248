import React from 'react'
import { NavLink } from 'react-router-dom'

const NavbarTop = (props) => {
    const {ArrayData} = props;
    return (
        <section className='navbar__top'>
            <div className="navbar__top__one">
                <div className="navbar__top__one__title">
                    <h2>الرئيسية</h2>
                </div>
                <div className="navbar__top__one__list">
                    <NavLink to={"/notifications"} className={"icon-notification"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M16.4744 9.70444C15.8657 8.99357 15.5891 8.37754 15.5891 7.33096V6.97511C15.5891 5.61128 15.2752 4.73255 14.5927 3.85383C13.5409 2.48916 11.7702 1.66666 10.0367 1.66666H9.96298C8.26596 1.66666 6.55072 2.45139 5.48067 3.76067C4.76095 4.65702 4.41061 5.57351 4.41061 6.97511V7.33096C4.41061 8.37754 4.15221 8.99357 3.52524 9.70444C3.06393 10.2282 2.9165 10.9013 2.9165 11.6298C2.9165 12.3591 3.15586 13.0498 3.63623 13.6113C4.26319 14.2844 5.14855 14.7141 6.05296 14.7888C7.36238 14.9382 8.67179 14.9944 10.0003 14.9944C11.3279 14.9944 12.6373 14.9004 13.9475 14.7888C14.8511 14.7141 15.7365 14.2844 16.3634 13.6113C16.843 13.0498 17.0832 12.3591 17.0832 11.6298C17.0832 10.9013 16.9357 10.2282 16.4744 9.70444Z" fill="#5570F1" />
                            <path opacity="0.4" d="M11.674 16.0236C11.2574 15.9347 8.71888 15.9347 8.30229 16.0236C7.94616 16.1059 7.56104 16.2972 7.56104 16.7169C7.58174 17.1172 7.81613 17.4706 8.14079 17.6946L8.13996 17.6955C8.55987 18.0228 9.05266 18.2309 9.56864 18.3056C9.8436 18.3434 10.1235 18.3417 10.4084 18.3056C10.9236 18.2309 11.4164 18.0228 11.8363 17.6955L11.8355 17.6946C12.1601 17.4706 12.3945 17.1172 12.4152 16.7169C12.4152 16.2972 12.0301 16.1059 11.674 16.0236Z" fill="#5570F1" />
                        </svg>
                    </NavLink>
                    <NavLink className="logo"  to={"/profile-personly"}>
                        <img src="/assets/images/icon-logo-sm.png" alt="logo" />
                    </NavLink>
                </div>
            </div>
            <div className="navbar__top__two">
                <NavLink to={"/"} className={"icon_home"}>
                    <img src="/assets/icons/icon-home.svg"  alt="" />
                </NavLink>
                
                <>
                    {ArrayData.length === 0 ? "" :
                        ArrayData.map((item, index) => {
                            return (
                                <span key={index} className="navbar__top__two__links">
                                    <span className="line">/</span>
                                    <NavLink to={item.href}>
                                        {item.title}
                                    </NavLink>
                                </span>
                            )
                        })}
                    </>
            </div>
        </section>
    )
}

export default NavbarTop