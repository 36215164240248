import React from 'react'
import ListStudentsTable from './list-students-table'
import ListStudentsTop from './list-students-top'

const ListStudentsContent = (props) => {
  const { ArrayData } = props;
  return (
    <div className='students-content'>
        <ListStudentsTop />
        <ListStudentsTable ArrayData={ArrayData}/>
    </div>
  )
}

export default ListStudentsContent