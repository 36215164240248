import React from 'react'
import ProfilePersonlyForm from './form'

const ProfilePersonlyContent = () => {
  return (
    <div className='settings-content'>
        <h6 className="title">الملف الشخصي</h6>
        <div className="settings-content__body">
            <ProfilePersonlyForm/>
        </div>
    </div>
  )
}

export default ProfilePersonlyContent