import React from "react";
import ExamsTop from "./exams-top";
import ExamsTable from "./exams-table";

const ExamsContent = (props) => {
  const { ArrayData, perpage, pagenumber, setLoading, PageSize, setPageSize } =
    props;
  return (
    <div className="exams-content">
      <ExamsTop setLoading={setLoading} />
      <ExamsTable
        ArrayData={ArrayData}
        perpage={perpage}
        pagenumber={pagenumber}
        setLoading={setLoading}
        PageSize={PageSize}
        setPageSize={setPageSize}
      />
    </div>
  );
};

export default ExamsContent;
