import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { Api } from "../../../api/index.js";
import { Headers } from "../../../api/actions.js";
import Loading from "../../../components/loading/loading.jsx";

import NavbarLeft from "../../../components/navbar/navbar_left.jsx";
import NavbarTop from "../../../components/navbar/navbar_top.jsx";
import ChoiceQuestionContent from "../component/edite-question-content/index.jsx";
import axiosInstance from "../../../api/axios_instance.jsx";

const EditeQuestion = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    GetData(id, setData, setLoading);
  }, [id]);

  const ObJectArrayData = [
    {
      id: 2,
      href: `/questionbanck/page:${1}/search:${""}/sortclassroom:${""}`,
      title: "بنك الاسئلة",
    },
  ];

  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <ChoiceQuestionContent data={data} />
        </div>
      </div>
    </section>
  );
};

export default EditeQuestion;

const GetData = async (id, setData, setLoading) => {
  var data = new FormData();
  data.append("id", id);

  const options = {
    method: "post",
    url: `${Api}Question/GetQuestionById`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      //  console.log(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
