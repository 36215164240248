import React from "react";

const IconSearch = (props) => {
    const {SearchClick} = props;
  return (
    <button type="button" className="icon" onClick={SearchClick}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <circle
        cx="9.80589"
        cy="10.3055"
        r="7.49047"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0156 15.9042L17.9523 18.8333"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    </button>
  );
};

export default IconSearch;
