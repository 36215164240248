import React, {  useState } from 'react';
export const Authcontext = React.createContext();

export function Contextprovider(props) {
    //Wishlist
    const [wishlist, setWishlist] = useState([]);


    const value = {
        //Wishlist
        wishlist: wishlist,
        setWishlist: setWishlist,
    }

    return (
        <Authcontext.Provider value={value}>
            {props.children}
        </Authcontext.Provider>
    )
}

