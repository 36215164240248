import React from "react";
import ModalAddAllQuestion from "./modal-add-question";
import { useNavigate, useParams } from "react-router";

const AddAllQuestionTop = () => {
  return (
    <>
      <div className="gap-30 justify-content-end d-flex align-items-center w-100">
        <button
          type="button"
          className="btn btn-download"
          data-bs-toggle="modal"
          data-bs-target="#ModalAddAllQuestion"
        >
          <span className="icon">
            <img src="/assets/icons/icon-download.svg" alt="" />
          </span>
          <span className="text">رفع ملف اكسيل</span>
        </button>

        <QuestionDropdown />
      </div>
      <ModalAddAllQuestion />
    </>
  );
};

export default AddAllQuestionTop;

const QuestionDropdown = () => {
  let navigate = useNavigate();
  const { id } = useParams();

  const handleCLick = () => {
    navigate(`/questionbanck/add-question/subjectid:${id}`);
  };

  return (
    <div className="dropdown">
      <button
        type="button"
        onClick={handleCLick}
        className="btn btn-primary-blue btn-show-dropdown"
      >
        <div>
          <Icon />
          <span className="text">إضافة سؤال </span>
        </div>
      </button>
    </div>
  );
};

const Icon = () => {
  return (
    <span className="icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M12.5 5V19"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5 12H19.5"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
