import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import IconSearch from "../../../components/shared/icon-search";

const PowersTop = () => {
  let navigate = useNavigate();
  const [search, setSearch] = useState("");
  const SearchClick = (e) => {
    e.preventDefault();
    navigate(`/powers/search:${search}`);
  };
  return (
    <>
      <div className="add_data justify-content-end d-flex w-100">
        <NavLink className="btn btn-add" to={"/powers/add"}>
          <span className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M12.5 5V19"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.5 12H19.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span className="text">إضافة صلاحية جديدة</span>
        </NavLink>
      </div>
      <div className="powers-content__top">
        <div className="powers-content__top__title">
          <h5>الصلاحيات</h5>
        </div>

        <div className="powers-content__top__filter">
          <div className="powers-content__top__search">
            <IconSearch SearchClick={SearchClick} />
            <input
              type="text"
              placeholder="بحث"
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  navigate(`/powers/search:${e.target.value}`);
                }
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PowersTop;
