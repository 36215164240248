/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Api } from "../../../../api";
import axios from "axios";
import { Headers } from "../../../../api/actions";
import axiosInstance from "../../../../api/axios_instance";

function AddFormCardOne(props) {
  const {
    classroomId,
    subjectId,
    Day,
    setDay,
    testDuration,
    setTestDuration,
    startTime,
    setStartTime,
    Error,
    setError,
  } = props;
  const [code, setCode] = useState("");
  const [codeSubject, setCodeSubject] = useState("");
  return (
    <div className="exams-content-add__form__card">
      <h4 className="size-h4"> أولاً تفاصيل الاختبار</h4>
      <div className="exams-content-add__form__card__inputs">
        <InputDropdownClassRooms
          Value={classroomId}
          setCode={setCode}
          TitleData=" اسم الدورة"
        />
        <InputCode Value={code} TitleData="رمز الدورة" />
        <InputDropdownSubject
          classRoom={classroomId}
          Value={subjectId}
          setCodeSubject={setCodeSubject}
          TitleData="اسم المادة"
        />
        <InputCode Value={codeSubject} TitleData="رمز المادة" />
      </div>
      <div className="exams-content-add__form__card__inputs">
        <div className="exams-content-add__form__card__inputs__rows">
          <InputDatePicker
            Value={Day}
            setValue={setDay}
            Error={Error}
            setError={setError}
          />
          <InputTestDuration
            Value={testDuration}
            setValue={setTestDuration}
            Error={Error}
            setError={setError}
          />
        </div>
        <br />
        <div className="exams-content-add__form__card__inputs__rows">
          <InputTimeStart
            Value={startTime}
            setValue={setStartTime}
            Error={Error}
            setError={setError}
          />
          <br />
          <br />
          <div className="exams-content-add__form__card__inputs__rows__item"></div>
        </div>
      </div>
    </div>
  );
}

export default AddFormCardOne;

const InputTestDuration = (props) => {
  const { Value, setValue, Error, setError } = props;

  const OnChinge = (e) => {
    e.preventDefault();
    setValue(e.target.value);
    setError({ ...Error, testDuration: "" });
  };
  return (
    <div className="exams-content-add__form__card__inputs__rows__item">
      <label>مدة الاختبار</label>
      <div className="input-test-duration">
        <input
          type="number"
          placeholder="مدة الاختبار"
          value={Value}
          onChange={(e) => {
            OnChinge(e);
          }}
        />
        <span className="text">دقيقة</span>
      </div>
      {Error.testDuration && (
        <span className="error-text">{Error.testDuration}</span>
      )}
    </div>
  );
};
const InputDatePicker = (props) => {
  const { Value, setValue, Error, setError } = props;
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 5 && day !== 6;
  };
  return (
    <div className="exams-content-add__form__card__inputs__rows__item">
      <label>موعد بداية الاختبار</label>
      <DatePicker
        selected={Value}
        onChange={(date) => {
          setValue(date);
          setError({ ...Error, day: "" });
        }}
        showIcon
        showMonthDropdown
        showYearDropdown
        filterDate={isWeekday}
        minDate={new Date()}
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" />
          </svg>
        }
      />
      {Error.day && (
        <>
          <br />
          <span className="error-text">{Error.day}</span>
          <br />
        </>
      )}
    </div>
  );
};
const InputTimeStart = (props) => {
  const { Value, setValue, PlaceHolder, Error, setError } = props;
  return (
    <div className="exams-content-add__form__card__inputs__rows__item">
      <label>موعد بداية الاختبار</label>
      <DatePicker
        selected={Value}
        onChange={(date) => {
          setValue(date);
          setError({ ...Error, startTime: "" });
        }}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={30}
        placeholderText={PlaceHolder}
        minTime={new Date(0, 0, 0, 8, 0)}
        maxTime={new Date(0, 0, 0, 20, 0)}
        timeCaption="Time"
        dateFormat="h:mm aa"
        showIcon
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
          </svg>
        }
      />
      {Error.startTime && <span className="error-text">{Error.startTime}</span>}
    </div>
  );
};

const InputCode = (props) => {
  const { Value, TitleData } = props;

  return (
    <div className="form-group">
      <label htmlFor=""> {TitleData}</label>
      <input type="text" className="form-control" value={Value} disabled />
    </div>
  );
};
const InputDropdownClassRooms = (props) => {
  const { Value, setCode, TitleData } = props;
  const [isLoading, setLoading] = useState(true);
  const [nameClassRoom, setNameClassRoom] = useState("");

  useEffect(() => {
    GetDataClassRooms(setNameClassRoom, setCode, Value, setLoading);
  }, []);

  if (isLoading === false)
    return (
      <div className="form-group">
        <label htmlFor=""> {TitleData}</label>
        <div className="btn-group">
          <button type="button" className={"btn  dropdown-toggle"}>
            <span className="size_body weight_500 text">{TitleData} </span>
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M6.5 9L12.5 15L18.5 9"
                  stroke="#5E6366"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
    );

  return (
    <div className="form-group">
      <label htmlFor=""> {TitleData}</label>
      <input
        type="text"
        className="form-control"
        value={nameClassRoom}
        disabled
      />
    </div>
  );
};

const InputDropdownSubject = (props) => {
  const { classRoom, Value, setCodeSubject, TitleData } = props;
  const [isLoading, setLoading] = useState(true);
  const [nameSubject, setSubject] = useState("");

  useEffect(() => {
    GetDataSubjectClassRooms(
      classRoom,
      Value,
      setSubject,
      setCodeSubject,
      setLoading
    );
  }, [classRoom]);

  if (isLoading === false)
    return (
      <div className="form-group">
        <label htmlFor=""> {TitleData}</label>
        <div className="btn-group">
          <button type="button" className={"btn  dropdown-toggle"}>
            <span className="size_body weight_500 text">{TitleData} </span>
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M6.5 9L12.5 15L18.5 9"
                  stroke="#5E6366"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
    );

  return (
    <div className="form-group">
      <label htmlFor=""> {TitleData}</label>
      <input
        type="text"
        className="form-control"
        value={nameSubject}
        disabled
      />
    </div>
  );
};
const GetDataSubjectClassRooms = async (
  classRoom,
  Value,
  setSubject,
  setCodeSubject,
  setLoading
) => {
  var data = new FormData();
  data.append("id", classRoom);
  const options = {
    method: "post",
    url: `${Api}Subject/GetAllSubjectsByClassRoom`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      for (let i = 0; i < response.data.responseObject.length; i++) {
        if (response.data.responseObject[i].id == Value) {
          setSubject(response.data.responseObject[i].nameAr);
          setCodeSubject(response.data.responseObject[i].code);
        }
      }
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
const GetDataClassRooms = async (
  setNameClassRoom,
  setCode,
  Value,
  setLoading
) => {
  const options = {
    method: "get",
    url: `${Api}ClassRoom/GetClassRooms`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      for (let i = 0; i < response.data.responseObject.length; i++) {
        if (response.data.responseObject[i].id == Value) {
          setNameClassRoom(response.data.responseObject[i].nameAr);
          setCode(response.data.responseObject[i].code);
        }
      }
    })
    .catch(function (error) {
      setLoading(true);
    });
};
