import React from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";

const CoursesTable = (props) => {
  const { ArrayData, perpage, pagenumber, setLoading, PageSize, setPageSize } =
    props;

  const { search, active } = useParams();
  const activeCourse = active.slice(active.indexOf(":") + 1, active.length);
  let navigate = useNavigate();
  const searchKeyword = search.slice(search.indexOf(":") + 1, search.length);

  const handlePageClick = (data) => {
    let number = 1 + data.selected;
    navigate(
      `/courses/page:${number}/search:${searchKeyword}/active:${activeCourse}`
    );
    setLoading(false);
  };

  return (
    <div className="table-container">
      <CoursesTableHead />

      <ul className="table-container__body">
        {ArrayData.map((item) => {
          return (
            <li key={item.id}>
              <CoursesTableBody Item={item} />
            </li>
          );
        })}
      </ul>
      <Paginate
        handlePageClick={handlePageClick}
        perpage={perpage}
        pagenumber={pagenumber}
        setLoading={setLoading}
        PageSize={PageSize}
        setPageSize={setPageSize}
      />
    </div>
  );
};

export default CoursesTable;

const CoursesTableHead = () => {
  return (
    <ul className="table-container__head">
      <li>
        <span className="justify-content-start">رقم الدورة</span>
        <span>رمز الدورة</span>
        <span>اسم الدورة</span>
        <span>الحالة</span>
        <span className="">الإجراءات</span>
      </li>
    </ul>
  );
};

const CoursesTableBody = (props) => {
  const { Item } = props;
  return (
    <>
      <span className="justify-content-start">{Item.number}</span>
      <span>{Item.code}</span>
      <span>{Item.nameAr}</span>
      <span className={Item.isActive ? "active-list" : ""}>
        {Item.isActive === true ? "نشطة" : "منتهية"}
      </span>
      <span className="link-list ">
        <NavLink to={`/courses/subjects/${Item.id}/page:${1}/search:${""}`}>
          <span>استعراض</span>
        </NavLink>
        <NavLink
          to={`/students/page:${1}/search:/sortclassroom:${
            Item.nameAr
          }/sortclasscode:${Item.code}/sortclassroomnamber:${Item.number}`}
        >
          <span>الطلبة</span>
        </NavLink>
      </span>
    </>
  );
};

function Paginate(props) {
  const {
    handlePageClick,
    perpage,
    pagenumber,
    setLoading,
    PageSize,
    setPageSize,
  } = props;
  const SelectValue = [
    {
      id: 1,
      value: 10,
      title: "10 صفوف",
    },
    {
      id: 2,
      value: 20,
      title: "20 صفوف",
    },
    {
      id: 3,
      value: 30,
      title: "30 صفوف",
    },
    {
      id: 4,
      value: 40,
      title: "40 صفوف",
    },
    {
      id: 5,
      value: 50,
      title: "50 صفوف",
    },
  ];

  return (
    <div className="paginate_control">
      <div className="paginate_control__select">
        <label htmlFor="">
          <span>عدد الصفوف</span>
        </label>
        <select
          className="form-select"
          value={PageSize}
          onChange={(e) => {
            setPageSize(parseInt(e.target.value));
            setLoading(false);
          }}
        >
          <>
            {SelectValue.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              );
            })}
          </>
        </select>
      </div>
      <ReactPaginate
        previousLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9 18L15 12L9 6"
              stroke="#64748B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        nextLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 6L9 12L15 18"
              stroke="#94A3B8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        breakLabel={"..."}
        pageCount={perpage}
        postsPerPage={2}
        pageSize={1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        renderOnZeroPageCount={null}
        forcePage={pagenumber - 1}
      />
    </div>
  );
}
